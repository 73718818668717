import { configData } from "./configData"

var languageContext = new Map()

languageContext.set(configData.italian,{
    changeLanguage:'Cambia lingua',
    italian:'Italiano',
    english:'Inglese',
    disconnect:'Disconnetti',
    itineraries:'Itinerari',
    init_config:'Configurazione iniziale',
    user_data:'Configurazione dati utente',
    visited:'Cronologia itinerari visitati',
    language:'Lingua',
    delete_acc:'Cancella account',
    version:'Versione',
    categories:'Categorie',
    next:'Avanti',
    discover:'Scopri',
    disc_message:"Di seguito è possibile specificare alcune preferenze relative ai percorsi che verranno consigliati dall'app.",
    choose:'Scegli',
    choose_message:"Di seguito è possibile indicare le fasce di prezzo relative a quanto si è disposti a spendere per un viaggio con i relativi pernottamenti nell'arco settimanale.",
    price:'Prezzo',
    start_day:'Giorno di inizio',
    duration:'Durata',
    day_period:'Periodo del giorno',
    monday:'Lunedì',
    tuesday:'Martedì',
    wednesday:'Mercoledì',
    thursday:'Giovedì',
    friday:'Venerdì',
    saturday:'Sabato',
    sunday:'Domenica',
    morning:'Mattina',
    afternoon:'Pomeriggio',
    search:'Cerca',
    close:'Chiudi',
    no_search:'Non sono stati trovati itinerari',
    suggested:'Suggeriti',
    nearest:'Vicini',
    favourite:'Preferiti',
    day_s:'giorno/i',
    category:'Categorie',
    points:'Punti',
    loading:'Caricamento',
    first_part_share_it:"Ho scoperto l'itinerario ",
    second_part_share:" grazie ad ASSD. Registrati e inizia a generare anche tu i tuoi itinerari!",
    share:'Condividi',
    no_reviews:'Non sono presenti recensioni',
    reviews_for:'Recensioni per ',
    travel_date:'Data di viaggio: ',
    group_type:'Tipo di gruppo: ',
    members_number:'Numero membri: ',
    rating:'Valutazione',
    date:'Data',
    travel_members_number:'Numero membri di viaggio',
    travel_group_type:'Tipo gruppo di viaggio',
    description:'Descrizione',
    pois:'Punti di interesse',
    visiting_time:'Tempo di visita(minuti)',
    revisit:'Rivisita',
    also_knows_as:'Anche conosciuto come:',
    entry_price:'Prezzo di entrata: €',
    phone:'Telefono:',
    timetables:'Orari:',
    time_visit:'Tempo di visita: ',
    visited_pois:'Punti di interesse visitati:',
    system_generation:'Il sistema sta generando gli itienerari',
    no_itineraries:'Non sono presenti itinerari',
    first_part_share_poi:"Ho scoperto ",
    finished:'FINITO',
    success_review:'Recensione effettuata con successo',
    rev_error:'Compila tutti i campi della recensione',
    poi_error:'Compila tutti i campi per la recensione di un poi',
    send_review:'INVIA RECENSIONE',
    user_data_sub:'I seguenti dati sono utili a garantire una migliore qualità degli itinerari suggeriti.',
    birth_date:'Data di nascita',
    sex:'Sex',
    country:'Nazione',
    region:'Regione',
    province:'Provincia',
    occupation:'Occupazione',
    study_level:'Titolo di studio',
    send_data:'INVIA I DATI',
    male:'Maschio',
    female:'Femmina',
    not_specified:'Non specificato',
    family_trip:'Viaggio di famiglia',
    friend_trip:'Viaggio con amici',
    individual_trip:'Viaggio da solo',
    couple_trip:'Viaggio di coppia',
    all_field_error:'Devi compilare tutti i campi!',
    data_updated:'I tuoi dati sono stati aggiornati!',
    make_review:'Recensisci '
    

})

languageContext.set(configData.english,{
    changeLanguage:'Change language',
    italian:'Italian',
    english:'English',
    disconnect:'Disconnect',
    itineraries:'Itineraries',
    init_config:'Initial configuration',
    user_data:'User data configuration',
    visited:'Visited itineraries chronology',
    language:'Language',
    delete_acc:'Delete account',
    version:'Version',
    categories:'Categories',
    next:'Next',
    discover:'Discover',
    disc_message:"Below you can specify some preferences related to the routes that will be recommended by the app.",
    choose:'Choose',
    choose_message:"Below you can indicate the price ranges relating to what you are willing to spend on a trip with the relative overnight stays during the week.",
    price:'Price',
    start_day:'Starting day',
    duration:'Duration',
    day_period:'Day period',
    monday:'Monday',
    tuesday:'Tuesday',
    wednesday:'Wednesday',
    thursday:'Thursday',
    friday:'Friday',
    saturday:'Saturday',
    sunday:'Sunday',
    morning:'Morning',
    afternoon:'Afternoon',
    search:'Search',
    close:'Close',
    no_search:'No itineraries were found',
    suggested:'Suggested',
    nearest:'Nearest',
    favourite:'Favourite',
    day_s:'day/s',
    category:'Categories',
    points:'Points',
    loading:'Loading',
    first_part_share_it:"I discovered the itinerary ",
    second_part_share:" thanks to ASSD. Register and start generating your own itineraries too!",
    share:'Share',
    no_reviews:'There are no reviews',
    reviews_for:'Reviews for ',
    travel_date:'Travel date: ',
    group_type:'Group type: ',
    members_number:'Members number: ',
    rating:'Rating',
    date:'Date',
    travel_members_number:'Travel members number',
    travel_group_type:'Travel group type',
    description:'Description',
    pois:'Point of interest',
    visiting_time:'Visiting time(minutes)',
    revisit:'Revisit',
    also_knows_as:'Also knows as',
    entry_price:'Entry price: €',
    phone:'Phone:',
    timetables:'Timetables:',
    time_visit:'Visiting time: ',
    visited_pois:'Visited points of interest:',
    system_generation:'The system is generating itieneraries',
    no_itineraries:'There are no itineraries',
    first_part_share_poi:"I discovered ",
    finished:'FINISHED',
    success_review:'Review made successfully',
    rev_error:'Fill in all the fields of the review',
    poi_error:'Fill in all the fields for a poi review',
    send_review:'SEND REVIEW',
    user_data_sub:'The following data are useful to guarantee a better quality of the suggested itineraries.',
    birth_date:'Birth date',
    sex:'Sex',
    country:'Country',
    region:'Region',
    province:'Province',
    occupation:'Occupation',
    study_level:'Study level',
    send_data:'Send data',
    male:'Male',
    female:'Female',
    not_specified:'Not specified',
    family_trip:'Family trip',
    friend_trip:'Friend_trip',
    individual_trip:'Individual trip',
    couple_trip:'Couple trip',
    all_field_error:'You must fill in all the fields!',
    data_updated:'Your data has been updated!',
    make_review:'Make review for '
    
})
export {languageContext}