import React from 'react';
import { connect } from 'react-redux';
import itinerariesPlaceHolder from '../restUtilities/itinerariesRest/itinerariesPlaceHolder';
import { CircularProgress, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ItineraryPlaceHolder from '../restUtilities/itinerariesRest/itinerariesPlaceHolder';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import {FacebookShareButton,TwitterShareButton,WhatsappShareButton,FacebookIcon,TwitterIcon,WhatsappIcon} from 'react-share';
import Popover from '@material-ui/core/Popover';
import PoiMap from './poiMap/PoiMap';
import { changePage } from '../action/changePageAction'
import { languageContext } from '../languageContext';


const styles = theme => ({
    title: {
        textAlign: 'center',
        color: '#1E90FF',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    subTitle: {
        textAlign: 'center',
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        paddingTop: '10px',
        paddingBottom: '15px'
    },
    centerDiv: {
        textAlign: 'center',
    },
    colorText: {
        color: '#1E90FF',
    },
    duration: {
        textAlign: 'center',
        color: '#1E90FF',
        marginTop: theme.spacing(3)
    },
    sameLine: {
        display:'inline',
        margin: theme.spacing(2)
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    tile: {
        width: '50px !important' ,
    },
    tags: {
        textAlign: 'center',
        color: '#1E90FF',
        margin: theme.spacing(3)
    },
    image: {
        height: '100%',
        width: '100%'
    },
    root: {
        height: '100vh',
    },
});

class ItineraryDetails extends React.Component{
    
    state = {itinerary : null, popover : null, coordinate:[], pois:[]};

    back = () => {
        this.props.history.goBack()
    }

    handleFavourite = async (event,value) =>{
        if(this.state.itinerary.marked_as_pref) {
            await ItineraryPlaceHolder.put("/" + this.props.userId + "/" + this.props.match.params.id + "/false")
            this.setState(prevState => {
                let itinerary = Object.assign({}, prevState.itinerary); 
                itinerary.marked_as_pref = false;                  
                return { itinerary };                                 
              })
        } else {
            await ItineraryPlaceHolder.put("/" + this.props.userId + "/" + this.props.match.params.id + "/true")
            this.setState(prevState => {
                let itinerary = Object.assign({}, prevState.itinerary); 
                itinerary.marked_as_pref = true;                 
                return { itinerary };                                 
              })
        }
    }

    componentDidMount = async () => {
        this.props.changePage("ItineraryDetails")
        const response  = await itinerariesPlaceHolder('/' + this.props.userId + '/' + this.props.match.params.id);
        var coord = []
        var pois = []
        response.data.pois.forEach( (poi)=>{
            coord.push({lat:poi.latitude,lng:poi.longitude,path_position:poi.path_position})
            pois.push({lat:poi.latitude,lng:poi.longitude,name:poi.short_description,id:poi.poi_id})
        });
        var sortedCoords=coord.sort((a,b)=>a.path_position<b.path_position?-1:a.path_position>b.path_position?1:0);
        console.log(sortedCoords);
        this.setState({itinerary:response.data,coordinate:sortedCoords,pois:pois});
        console.log(response.data);
    }

    handleClick = (event) => {
        //setAnchorEl(event.currentTarget);
        this.setState({popover: event.currentTarget})
    };

    handleClose = () => {
        //setAnchorEl(null);
        this.setState({popover: null})
    };

    render() {
        const { classes } = this.props;
        return(
            !this.state.itinerary ?
            <div className = {classes.centerDiv}>
            <h2>{languageContext.get(this.props.language).loading}</h2>
            <CircularProgress />
            </div> :
            <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={7} component={Paper} elevation={6} square>
            <div>
            <IconButton aria-label="favourite" onClick={this.back}>
                <ArrowBackIosIcon />
            </IconButton>
            </div>
            <div>               
            <Typography variant="h4" className= {classes.title} > {this.state.itinerary.name} </Typography>
            </div>
            <div>
                <PoiMap coordinate={this.state.coordinate} pois={this.state.pois} />
            </div>
            <div>
            <Typography className={classes.subTitle}> {this.state.itinerary.description} </Typography>
            </div>
            <div className={classes.centerDiv}>

            <Rating name="disabled" value={this.state.itinerary.score === -1 ? 0 : this.state.itinerary.score} disabled />
            <Typography component="legend" className={classes.colorText}>{languageContext.get(this.props.language).points} {this.state.itinerary.score === -1 ? 0 : this.state.itinerary.score}</Typography>
            </div>
            <div className={classes.duration}>
                <div className={classes.sameLine}>
                   <b> {languageContext.get(this.props.language).duration+": " + this.state.itinerary.duration + " "+languageContext.get(this.props.language).day_s} </b>
                </div>
                <div className={classes.sameLine}>
                <b> {languageContext.get(this.props.language).category +": " + this.state.itinerary.category} </b>
                </div>
            </div>
            <div className={classes.tags}>
            {
                this.state.itinerary.tags.map((tag) =>
                '#' + tag + " "
                )
            }
            </div>
            <div className={classes.centerDiv}>
            <Link to = {"/reviews/" + this.state.itinerary.id} params={{ testvalue: "hello" }}>
            <IconButton aria-label="reviews">
                <CommentIcon />
            </IconButton>
            </Link>
            <IconButton aria-label="share" onClick={this.handleClick}>
                <ShareIcon />
            </IconButton>
            <Popover
                id={1}
                open={this.state.popover}
                anchorEl={this.state.popover}
                onClose={this.handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
                }}
            >
                <Typography style={{margin:'20px', marginBottom:'0px', textAlign:'center'}}><b>{languageContext.get(this.props.language).share}</b></Typography>
                <div style={{textAlign:'center'}}>
                    <FacebookShareButton
                        url='https://assd-webapp.vtserver.eu/'
                        quote={languageContext.get(this.props.language).first_part_share_it + this.state.itinerary.name + languageContext.get(this.props.language).second_part_share}
                        style={{margin:'15px'}}
                    >
                    <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                        url='https://assd-webapp.vtserver.eu/'
                        title={languageContext.get(this.props.language).first_part_share_it + this.state.itinerary.name + languageContext.get(this.props.language).second_part_share}
                        style={{margin:'15px'}}
                    >
                    <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <WhatsappShareButton
                        url='https://assd-webapp.vtserver.eu/'
                        title={languageContext.get(this.props.language).first_part_share_it + this.state.itinerary.name + languageContext.get(this.props.language).second_part_share}
                        style={{margin:'15px'}}
                    >
                    <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </div>
                </Popover>
            {!this.state.itinerary.marked_as_pref ?
            <IconButton aria-label="favourite" onClick={this.handleFavourite}>
                <FavoriteIcon />
            </IconButton>
            : <IconButton aria-label="favourite" onClick={this.handleFavourite}>
                <FavoriteIcon color='error'/>
            </IconButton>
            
            }
            <div>
            {this.state.itinerary.marked_as_pref ?
                <Link to = {"/makereview/" + this.state.itinerary.id}>
                    <Button aria-label="write-review" color='primary' variant="contained">
                        <CreateIcon />
                    </Button>
                </Link>
            : null
            }
            </div>
            </div>
            </Grid>
            <Grid item xs={12} sm={8} md={5}>
                <img src={this.state.itinerary.url_image} className={classes.image}></img>
            </Grid>   
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        userId:state.user.userId,
        language: state.user.language
    });
}

export default connect(mapStateToProps,{
    changePage:changePage
})(withStyles(styles)(ItineraryDetails));