import userPlaceHolder from '../../restUtilities/userRest/userPlaceHolder'
import { configData } from '../../configData';

export const signIn  = (userName, userEmail, userImage, social) => async dispatch =>{
    const response = await userPlaceHolder.put(
        '',
        {
            email:userEmail,
            image:userImage,
            name:userName,
            
        }
    
    );
    const language = await userPlaceHolder.get(
        configData.getLanguageUrl+"/"+response.data
    )

    var firstAccess = false;
    if(localStorage.getItem('firstAccess')===null || localStorage.getItem('firstAccess')==="false" ){
        localStorage.setItem('firstAccess',"true");
        firstAccess=true;
    }
   
    dispatch ({
        type:'SIGN_IN',
        payload:{
            isSignedIn:true,
            userName:userName,
            userEmail:userEmail,
            userImage:userImage,
            userId:response.data,
            social:social,
            firstAccess:firstAccess,
            language:language.data
        }
    });
};

export const signOut = () => {
    
    
    localStorage.setItem('firstAccess',"false");

    return {
        type: 'SIGN_OUT'
    };
};

export const changeLanguage = (language,userId) => async dispatch=>{
    await userPlaceHolder.put(
        "/"+userId+"/"+language
    )
    dispatch({
        type:'CHANGE_LANGUAGE',
        payload:{language:language}
    })
}
