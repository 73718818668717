import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Logo from './images/assd-logo.png';
import FacebookAuth from './FacebookAuth';
import GoogleAuth from './GoogleAuth';
import TwitterAuth from './TwitterAuth';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  twitterButton:{
    width: '100%'
  },
  image: {
    backgroundImage: 'url(https://www.viaggi-estate.com/wp-content/uploads/2018/03/tropea.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    margin: theme.spacing(4, 2),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    margin: theme.spacing(1),
    backgroundColor: 'blue',
  },
}));

const LoginPage = () => {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
            <img src = { Logo } alt="ASSD"></img>
        </div>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Effettua l'accesso 
          </Typography>
          <div className={classes.form}> 
          <FacebookAuth></FacebookAuth>
          <GoogleAuth></GoogleAuth>
          <TwitterAuth classes = {classes}></TwitterAuth> 
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default LoginPage;