import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    marginBottom:{
        marginBottom: 7
    },
    root: {
        display: 'flex',
        marginRight: 50,
        marginLeft: 50,
        marginTop: 50
    },
    details: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    marginLeftAuto: {
        marginLeft: 'auto'
    },
    content: {
      flex: '1 0 auto',
    },
    marginRightRating: {
      marginRight: 30
    },
    bold: {
      fontWeight: 'bold'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    marginLeft20: {
        marginLeft: 20
    }
}));