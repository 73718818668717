import CategoriesPlaceHolder from '../../restUtilities/categoriesRest/categoriesPlaceHolder'

export const fetchAllCategories = async (language) =>{

    const response = await CategoriesPlaceHolder.get(
        '/all/'+language
    )
    return response.data;
}

export const fetchUserCategories = async (userId) => {
    const response = await CategoriesPlaceHolder.get(
        '/'+userId
    )

    return response.data.user_categories;
}

export const putUserCategories = async (userId,categories) => {
    const response = await CategoriesPlaceHolder.put(
        '/'+userId,
        {
            user_categories:categories
        }
    )

    return response.data;
}