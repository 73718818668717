import React from 'react'
import { connect } from 'react-redux'
import { signIn, signOut } from '../action/authActions'
import { FacebookLoginButton } from "react-social-login-buttons";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { configData } from '../configData'

class FacebookAuth extends React.Component {

    responseFacebook = (response) => {   
        if(response.accessToken) {
            this.props.signIn(response.name,response.email,response.picture.data.url,configData.facebook);
        } else {
            this.props.signOut();
        }
    }

    render () {
        return (
                <FacebookLogin
                appId="850088575494482"
                autoLoad={false}
                fields="name,email,picture"
                scope="public_profile,user_friends,email"
                callback={this.responseFacebook} 
                render={renderProps => (
                    <FacebookLoginButton onClick={renderProps.onClick} />
                  )}
                />         
        );
    }
}

export default connect(null,{signIn,signOut})(FacebookAuth);