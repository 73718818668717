import ItineraryPlaceHolder from '../../restUtilities/itinerariesRest/itinerariesPlaceHolder'
import { configData } from '../../configData'
export const fetchItinerary = (type, latitude, longitude,userId, source=null) => async dispatch => {
    var response;
    console.log(latitude+" "+longitude);
    if(type !== configData.favouriteItinerary){
        if(type===configData.suggestedItinerary){
            var isSuggested = type === configData.suggestedItinerary;
            
            response = await ItineraryPlaceHolder.get(
                "/"+isSuggested+"/"+userId+"/"+latitude+"/"+longitude,
                
            ).catch(err => {
                if (err.response.status === 404) {
                    return {data:[]}; // or an empty array or whatever you want
                }
                
            });
        }
        else{
            var isSuggested = type === configData.suggestedItinerary;
            
            response = await ItineraryPlaceHolder.get(
                "/"+isSuggested+"/"+userId+"/"+latitude+"/"+longitude,
                {
                    cancelToken:source.token
                }
                
            ).catch(err => {
                
                    return {data:[]};
                
                
            });
        }
    }
    else{
        response = await ItineraryPlaceHolder.get(
            configData.favoriteUrl+"/"+userId
            
        ).catch(err => {
            if (err.response.status === 404) {
                return {data:[]}; // or an empty array or whatever you want
            }
            
          });
    }
    console.log(response);
    dispatch (
        {
            type: "FETCH_ITINERARY",
            payload: { 
                        type: type,
                        itineraries:response.data

                    }
        }
    );
}

export const changeItineraryType = ( itineraryType ) => {
    return({
        type: "CHANGE_ITINERARY_TYPE",
        payload: { type: itineraryType }
    })
}

export const fetchVisitedItinerary = userId => async dispatch => {
    var response;
    response = await ItineraryPlaceHolder.get(configData.visitedUrl+"/"+userId).catch(
        error => {return {data:[]}}
    )

    dispatch(
        {
            type: "FETCH_VISITED",
            payload: {
                data:response.data
            }
        }
    )
}