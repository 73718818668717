import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { connect } from 'react-redux'
import { fetchAllCategories, fetchUserCategories, putUserCategories } from '../action/categoriesAction'
import { Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { languageContext } from '../languageContext';



const StyledRating = withStyles({
    iconFilled: {
      color: '#ff6d75',
    },
    iconHover: {
      color: '#ff3d47',
    },
    iconEmpty: {
        color: 'white',
      },

  })(Rating);

const useStyles = theme=> ({
  root: {
    height: '80vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display:'flex',
        flex:1,
        justifyContent:'center',
        marginTop:20

  },
  content: {
    height: '80vh',

  },
  gridList: {
    width: '80vh',
    height: 500,
  },
  icon: {
    color: 'rgba(0, 235, 255, 0)',
  },
  title: {
    textAlign: 'center',
    color: '#1E90FF',
    marginBottom: theme.spacing(1)
},
});

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
    class CategoriesPage extends React.Component {
        constructor(){
            super();
            //this.classes = useStyles();
            this.state={categories:[],selected:[]}
        }

        componentDidMount = async () =>{
            var categories = await fetchAllCategories(this.props.language);
            var selected = await fetchUserCategories(this.props.userId);

            this.setState({categories:categories, selected:selected});
            console.log(selected);
        }

        handleChange = (categoryCode,newValue) => {
            if(newValue!==1){
                this.setState({selected: this.state.selected.filter( (code) => {
                    return code!==categoryCode;
                } )});
            }
            else{
                this.setState({selected:[...this.state.selected,categoryCode]});
            }

            console.log(this.state.selected);
        }

        handlePress = () =>{
            console.log(this.state.selected);
            putUserCategories(this.props.userId, this.state.selected);
        }
        render(){
            const { classes } = this.props;

            return (

                <div className={classes.root}>
                    
                    <div className={classes.content}>
                    <Typography variant="h4" className={classes.title}> {languageContext.get(this.props.language).categories} </Typography>

                    <div className={classes.content} style={{marginTop:40}}>
                        <GridList cellHeight={180} className={classes.gridList}>
                            
                        
                            
                        
                            
                                {this.state.categories.map( (category, index) => {
                                    return(
                                        <GridListTile key={category.code}>
                                            <img src={`/images/categories/${index}.jpg`} alt={category.nome} />
                                            <GridListTileBar 
                                                title={category.nome}
                                                actionIcon={
                                                    <IconButton aria-label={`info about ${category.nome}`}>
                                                        <StyledRating 
                                                            name={category.nome}
                                                            defaultValue={this.state.selected.includes(category.code)?1:0}
                                                            precision={1}
                                                            max={1}
                                                            icon={<FavoriteIcon fontSize="inherit" />}
                                                            onChange={(event,newValue) => this.handleChange(category.code,newValue)}
                                                        />
                                                    </IconButton>
                                            }
                                            />
                                        </GridListTile>
                                    );
                                })}
                            
                        </GridList>
                        <div>
                            <Button variant="contained" color="primary" onClick={this.handlePress} style={{width:'100%',marginTop:20}} component={Link} to="/configuration/preferences">
                                {languageContext.get(this.props.language).next}
                            </Button>
                        </div>
                    </div>
                        
                    </div>
                </div>
                /*
                <div className={this.classes.root}>
                <GridList cellHeight={180} className={this.classes.gridList}>
                    <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                    <ListSubheader component="div">December</ListSubheader>
                    </GridListTile>
                    {tileData.map((tile) => (
                    <GridListTile key={tile.img}>
                        <img src={tile.img} alt={tile.title} />
                        <GridListTileBar
                        title={tile.title}
                        subtitle={<span>by: {tile.author}</span>}
                        actionIcon={
                            <IconButton aria-label={`info about ${tile.title}`} className={classes.icon}>
                            <InfoIcon />
                            </IconButton>
                        }
                        />
                    </GridListTile>
                    ))}
                </GridList>
                </div>
                */


            );
        }
    }

const mapStateToProps = (state) => {
    return(
        {
            userId:state.user.userId,
            language:state.user.language
        }
    );
} 
export default connect(mapStateToProps)(withStyles(useStyles)(CategoriesPage));