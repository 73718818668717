import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import Navbar from './Navbar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BuildIcon from '@material-ui/icons/Build';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ChatIcon from '@material-ui/icons/Chat';
import LanguageIcon from '@material-ui/icons/Language';
import RoomIcon from '@material-ui/icons/Room';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from "@material-ui/core/styles";
import { BrowserRouter, Route, Link } from 'react-router-dom';
import UserDataConfiguration from './UserDataConfiguration';
import ItineraryCronology from './ItineraryCronology';
import Chatbot from './Chatbot';
import Language from './Language';
import Privacy from './Privacy';
import ItinerarySelectorTab from './ItinerariesSelectorTab'
import ItinerariesSuggested from './ItinerariesSuggested'
import ItinerariesNearest from './itineraryNearest'
import ItinerariesFavourite from './ItineraryFavourite'
import { connect } from 'react-redux'
import { signOut, changeLanguage } from '../action/authActions'
import { configData } from '../configData'
import userPlaceHolder from '../restUtilities/userRest/userPlaceHolder'
import LandingPage from './LandingPage'
import UserPreferences from './UserPreferences'
import CategoriesPage from './CategoriesPage'
import Hidden from '@material-ui/core/Hidden';
import ItineraryDetails from './ItineraryDetails';
import MakeReview from './MakeReview';
import Reviews from './Reviews';
import PoiReviews from './PoiReviews';
import { changePage } from '../action/changePageAction'
import HomeIcon from '@material-ui/icons/Home'
import PoiDetail from './PoiDetail';
import LanguageDialog from './languageDialog/LanguageDialog';
import { languageContext } from '../languageContext';
import { fetchItinerary } from '../action/itineraryAction'


const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(5)
  },
});



class ClippedDrawer extends React.Component {

constructor(props){
    super(props);
    this.state={
        mobileOpen: false,
        languageOpen: false
    };
    this.handleDrawerToggle.bind(this);
}

handleDrawerToggle = () => {
   this.setState({mobileOpen: !this.state.mobileOpen});
};

handleDrawerClose = () => {
    this.setState({mobileOpen: false});
 };

deleteAccount = async () =>  {
    await userPlaceHolder.delete("/" + this.props.userId);
    this.signOut();
}

handlePageChange = page => {
    this.props.changePage(page);
    this.handleDrawerClose();
}

signOut = () => {
    if(this.props.social === configData.google){
        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                clientId:
                    '886631787561-od4ul6ihb6j4urns9rq458n3ljrfn2a5.apps.googleusercontent.com',
                scope: 'email'

            }).then( () => {
                var auth = window.gapi.auth2.getAuthInstance();
                auth.signOut();
                auth.disconnect();
                
            });
        });
    }
    else if(this.props.social === configData.facebook){
        window.FB.logout();
        this.props.signOut();

    }
    else{
        this.props.signOut();
    }
    window.location.assign("/")
}
changeLanguage = async language =>{
    if(language !== this.props.language){
        await this.props.changeLanguage(language,this.props.userId);
    }
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.makeRequest);
    }
    this.setState({languageOpen:false});
}

makeRequest = async (position) =>{

    await this.props.fetchItinerary(this.props.itineraryType,position.coords.latitude,position.coords.longitude,this.props.userId);
    

}
render() { 
    const { classes } = this.props;

    const drawer =(
        <div className={classes.drawerContainer}>
        <List>            
            <ListItem button key='Disconnetti' onClick ={this.signOut} >                   
                <ListItemText primary={languageContext.get(this.props.language).disconnect} /> 
                <ExitToAppIcon />
            </ListItem>
                            
            <ListItem button key='Itinerari' onClick ={()=>this.handlePageChange("suggested")} component={Link} to="/suggested">                            
                <ListItemText primary={languageContext.get(this.props.language).itineraries} />
                <HomeIcon />
            </ListItem>
            <ListItem button key='Configurazione iniziale' onClick ={()=>this.handlePageChange("configuration")} component={Link} to="/configuration">                            
                <ListItemText primary={languageContext.get(this.props.language).init_config} />
                <BuildIcon />
            </ListItem>
            <ListItem button key='Configurazione dati utente' component={Link} onClick ={()=>this.handlePageChange("userDataConfiguration")} to="/userdataconfiguration">               
                <ListItemText primary={languageContext.get(this.props.language).user_data} />
                <AccessibilityIcon/>
            </ListItem>
            <ListItem button key='Cronologia itinerari visitati' component={Link} onClick ={()=>this.handlePageChange(configData.chronologyPage)} to="/itinerarycronology">              
                <ListItemText primary={languageContext.get(this.props.language).visited} />
                <RoomIcon/> 
            </ListItem>
            <ListItem button key='Chatbot' component={Link} onClick ={()=>this.handlePageChange("chatbot")} to="/chatbot">              
                <ListItemText primary='Chatbot' />
                <ChatIcon/> 
            </ListItem>
            <ListItem button key='Lingua' onClick ={()=>this.setState({languageOpen:true})}>              
                <ListItemText primary={languageContext.get(this.props.language).language} />
                <LanguageIcon /> 
            </ListItem>
            <ListItem button key='Cancella account' onClick ={this.deleteAccount}>               
                <ListItemText primary={languageContext.get(this.props.language).delete_acc} />
                <DeleteIcon />
            </ListItem>
            
        </List>
        <Divider />
        <List>
            <ListItem button key='Versione'>
                    <ListItemText primary={languageContext.get(this.props.language).version +': 1.0' }/>              
            </ListItem>
        </List>
        </div>
    );

    return (
        <BrowserRouter>
        <div className={classes.root}>
        <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Navbar handleDrawerToggle = {this.handleDrawerToggle}/>
            </AppBar>
        <Hidden smUp implementation="css">
        <Drawer
            className={classes.drawer}
            variant="temporary"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
            paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            <Toolbar />
            {drawer}
        </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
            paper: classes.drawerPaper,
            }}
        >
            <Toolbar />
            {drawer}
        </Drawer>
        </Hidden>
        <main className={classes.content}>
            
            {this.props.firstAccess===true ? <Route path="/" exact component={LandingPage}/> :
            this.props.firstAccess===null ? <Route path="/" exact component={LandingPage}/> :
                <Route path="/" exact component={ItinerariesSuggested}/>
            }
            <Route path="/userdataconfiguration" exact component={UserDataConfiguration}/>
            <Route path="/itinerarycronology" exact component={ItineraryCronology}/>
            <Route path="/chatbot" exact component={Chatbot}/>
            <Route path="/language" exact component={Language}/>
            <Route path="/suggested" exact component={ItinerariesSuggested}/>
            <Route path="/favourite" exact component={ItinerariesFavourite}/>
            <Route path="/nearest" exact component={ItinerariesNearest}/>
            <Route path="/privacyapp" exact component={Privacy}/>
            <Route path="/configuration" exact component={LandingPage}/>
            <Route path="/configuration/preferences" exact component={UserPreferences}/>
            <Route path="/configuration/categories" exact component={CategoriesPage}/>
            <Route path="/itinerarydetails/:id" exact component={ItineraryDetails} />
            <Route path="/makereview/:id" exact component={MakeReview} />
            <Route path="/reviews/:id" exact component={Reviews} />
            <Route path="/poiDetail/:id" exact component={PoiDetail} />
            <Route path="/poireviews/:id" exact component={PoiReviews} />
            <LanguageDialog open={this.state.languageOpen} handleClose={this.changeLanguage} userLanguage={this.props.language} />
        </main>
        </div>
        </BrowserRouter>
    );
    }
}

const mapStateToProps = (state) => {
    return ({
        userId:state.user.userId,
        social:state.user.social,
        firstAccess:state.user.firstAccess,
        language:state.user.language,
        itineraryType:state.itineraries.itineraryType
    });
}
export default connect(mapStateToProps,{signOut, changePage, changeLanguage, fetchItinerary })(withStyles(styles)(ClippedDrawer));


/*
    Componente privacy nascosto, da aggiungere in seguito
    <ListItem button key='Privacy' component={Link} onClick ={this.handleDrawerClose} to="/privacyapp">               
        <ListItemText primary='Privacy' />
        <AssignmentIndIcon />
    </ListItem>

*/