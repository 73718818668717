import React from 'react';
import itinerariesPlaceHolder from '../restUtilities/itinerariesRest/itinerariesPlaceHolder';
import { CircularProgress, Button, Container } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import { languageContext } from '../languageContext';

const styles = theme => ({
    sameLine: {
        display: 'inline-block'
    },
    review: {
        border: '1px solid #1E90FF'
    },
    colorText: {
        color: '#1E90FF'
    },
    half: {
        display: 'inline-block',
        
    },
    centerDiv: {
        textAlign: 'center'
    },
    container: {
        marginLeft: theme.spacing(10),
        marginRight: theme.spacing(10),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    username: {
        marginLeft: theme.spacing(1),
        marginTop: '5px',
        marginBottom: '15px'
    }
})

const enumerative = {};
enumerative['COUPLE_TRIP'] = "Viaggio di coppia";
enumerative['INDIVIDUAL_TRIP'] = "Viaggio individuale";
enumerative['FRIENDS_TRIP'] = "Viaggio con amici";
enumerative['FAMILY_TRIP'] = "Viaggio di famiglia";

class Reviews extends React.Component {

    state = {reviews : [], itineraryName: undefined, isLoading: true};

    back = () => {
        this.props.history.goBack()
    }

    componentDidMount = async () => {
        const response =  await itinerariesPlaceHolder.get('/review/' + this.props.match.params.id + '/vote/desc');
        const responseIt  = await itinerariesPlaceHolder('/' + this.props.userId + '/' + this.props.match.params.id);
        this.setState({reviews : response.data.reviews, itineraryName: responseIt.data.name, isLoading: false});
    }

    render() {
        const { classes } = this.props;
        return(
            this.state.isLoading ?
            <div style={{textAlign:'center'}}>
            <h2>{languageContext.get(this.props.language).loading}</h2>
            <CircularProgress />
            </div> :
            <div>
            <IconButton aria-label="favourite" onClick={this.back}>
                <ArrowBackIosIcon />
            </IconButton>
            <div>
            <Typography variant="h4" style={{marginTop:'20px',marginBottom:'10px',color:'#1E90FF', textAlign:'center'}}>
                {languageContext.get(this.props.language).reviews_for + this.state.itineraryName}
            </Typography> 
            {this.state.reviews.length > 0 ?
                <div className={classes.container}>
                    {this.state.reviews.map((review) =>
                    <div style={{marginBottom: '10px'}} key={review.description}> 
                        <Card key={review.description}>
                            <CardContent>
                            <div className={classes.centerDiv}>
                            <Rating name="rating" value={review.rating} disabled />
                            <Typography variant="h5" component="h2" style={{marginBottom:'10px'}}>
                                {review.description}
                            </Typography>
                            </div>
                            <div style={{justifyContent : 'flex-start', display: 'flex'}}>
                            <div>
                                <Avatar src={review.user_image}></Avatar>
                            </div>
                            <div className={classes.username}>
                                <Typography variant="h6" component="h6">
                                    {review.user_name}
                                </Typography>
                            </div>
                            </div>
                            <Typography className={classes.colorText}>
                                {languageContext.get(this.props.language).travel_date + review.date.substr(0,10)}
                            </Typography>
                            <Typography className={classes.colorText}>
                                {languageContext.get(this.props.language).group_type + enumerative[review.group_type]}
                            </Typography>
                            <Typography className={classes.colorText}>
                                {languageContext.get(this.props.language).members_number + review.travel_members_number}
                            </Typography>
                            <div style={
                                {textAlign:'right'}
                            }>
                            <Link to = {"/itinerarydetails/" + this.props.match.params.id} >
                                <IconButton  aria-label="info" >
                                    <InfoIcon />
                                </IconButton>
                             </Link>
                             </div>
                            </CardContent>
                        </Card>
                        </div>
                    )}
            </div> :
            <div>
                <div style={{textAlign:'center'}}>
                   <h2> {languageContext.get(this.props.language).no_reviews} </h2>
                </div>
                </div> }   
            </div>                  
            </div>);
    }
}

const mapStateToProps = (state) => {
    return ({
        userId:state.user.userId,
        language: state.user.language
    });
}

export default connect(mapStateToProps)(withStyles(styles)(Reviews));