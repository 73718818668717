import React from 'react';
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';
import { connect } from 'react-redux'


class App extends React.Component {

    render(){
        if(this.props.isSignedIn === false){
            return (
                <div >
                    <LoginPage/>
                </div>
            );
        }
        return(
            <Dashboard />
        );
    }
}

const mapStateToProps = (state) => {
    return (
      {
        isSignedIn: state.user.isSignedIn,
        userName: state.user.userName,
        userEmail: state.user.userEmail,
        userImage: state.user.userImage,
        userId:state.user.userId
      } 
    )
  }
export default connect(mapStateToProps)(App);