import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Slider from '@material-ui/core/Slider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import userPlaceHolder from '../restUtilities/userRest/userPlaceHolder';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { changePage } from '../action/changePageAction'
import { languageContext } from '../languageContext';

const styles = theme => ({
    root: {
        height: '80vh'
    },
    title: {
        textAlign: 'center',
        color: '#1E90FF',
        marginBottom: theme.spacing(1)
    },
    subTitle: {
        textAlign: 'center',
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    itemForm: {
        marginLeft: theme.spacing(1),
        color: '#1E90FF',
        fontWeight: 'bold'
    },
    display: {
        marginLeft: theme.spacing(5),
        display: 'flex',
        justifyContent: 'flex-start'
    },
    icon: {
        color: '#1E90FF',
    },
    sliderContainer: {
        width: '200px',
        marginLeft: theme.spacing(5),
        marginBottom: theme.spacing(2)
    },
    button: {
        width: '200px',
        marginLeft: theme.spacing(5),
    },
    leftPart: {
        backgroundImage: 'url(https://esitur.com/images/Restyle/imageResized1920x1080/Tropea-viaggi-organizzati-in-bus.jpeg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        
    }
});

const marksPrice = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1000,
      label: '1000',
    }
];

const marksDay = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 7,
      label: '7',
    }
];

class UserPreferences extends React.Component {

    state = {maxExpense : null , duration : null , day_period : null , starting_day : null} 

    componentDidMount = async () => {
        const response = await userPlaceHolder.get('/configuration/' + this.props.userId);
        this.setState({maxExpense:response.data.max_expense , duration:response.data.duration,day_period:response.data.day_period,starting_day:response.data.starting_day});
    }

    onPriceChange = (event,value) => {
        this.setState({maxExpense : value});
        this.input.max_expense = value;
    }

    onDayChange = (event,value) => {
        this.setState({duration : value});
        this.input.duration = value;
    }

    onDayPeriodChange = (event,value) => {
        this.setState({day_period : event.target.value});
        this.input.day_period = event.target.value;
    }

    onStartingDayChange = (event,value) => {
        this.setState({starting_day : event.target.value});
        this.input.starting_day = event.target.value;
    }

    handleSubmit = async () => {
        await userPlaceHolder.put(
            "/configuration/" + this.props.userId,
            this.input
        );
        await userPlaceHolder.put(
            "/setInitialItinerary/" + this.props.userId,
        );
        this.props.changePage("suggested");
    }

    input = {
        min_expense:0,
        max_expense:0,
        duration:1,
        day_period: 0,
        starting_day: 0
    }
    

    render() {
        const { classes } = this.props;
        return( 
            <div className={classes.root}>
                <Typography variant="h4" className={classes.title}> {languageContext.get(this.props.language).choose} </Typography>
                <Typography className={classes.subTitle}> 
                    {languageContext.get(this.props.language).choose_message}
                </Typography>
                <Grid container component="main">
                
                <Grid item xs={12} sm={8} md={5} elevation={6} square>
                <div className={classes.display}>
                <LocalOfferIcon className={classes.icon}/>
                <Typography variant="subtitle1" className={classes.itemForm}>
                    {languageContext.get(this.props.language).price}
                </Typography>
                </div>
                <div className={classes.sliderContainer}>
                <Slider
                    min={0}
                    max={1000}
                    step={10}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    id="travel-price"
                    onChange = {this.onPriceChange}
                    value={this.state.maxExpense}
                    marks={marksPrice}
                />
                </div>
                <div className={classes.display}>
                <CalendarTodayIcon className={classes.icon}/>
                <Typography variant="subtitle1" className={classes.itemForm}>
                    {languageContext.get(this.props.language).start_day}
                </Typography>
                </div>
                <div className={classes.sliderContainer}>
                <Select
                    labelId="starting-day"
                    id="starting-day"
                    value={this.state.starting_day}
                    onChange = {this.onStartingDayChange}
                >
                    <MenuItem value={0}>{languageContext.get(this.props.language).monday}</MenuItem>
                    <MenuItem value={1}>{languageContext.get(this.props.language).tuesday}</MenuItem>
                    <MenuItem value={2}>{languageContext.get(this.props.language).wednesday}</MenuItem>
                    <MenuItem value={3}>{languageContext.get(this.props.language).thursday}</MenuItem>
                    <MenuItem value={4}>{languageContext.get(this.props.language).friday}</MenuItem>
                    <MenuItem value={5}>{languageContext.get(this.props.language).saturday}</MenuItem>
                    <MenuItem value={6}>{languageContext.get(this.props.language).sunday}</MenuItem>
                </Select>
                </div>
                <div className={classes.display}>
                <TimelapseIcon className={classes.icon}/>
                <Typography variant="subtitle1" className={classes.itemForm}>
                    {languageContext.get(this.props.language).duration}
                </Typography>
                </div>
                <div className={classes.sliderContainer}>
                <Slider
                    min={1}
                    max={7}
                    step={1}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    id="duration"
                    onChange = {this.onDayChange}
                    value={this.state.duration}
                    marks={marksDay}
                />
                </div>
                <div className={classes.display}>
                <Brightness4Icon className={classes.icon}/>
                <Typography variant="subtitle1" className={classes.itemForm}>
                    {languageContext.get(this.props.language).day_period}
                </Typography>
                </div>
                <div className={classes.sliderContainer}>
                <Select
                    labelId="day-period"
                    id="day-period"
                    value={this.state.day_period}
                    onChange = {this.onDayPeriodChange}
                >
                    <MenuItem value={0}>{languageContext.get(this.props.language).morning}</MenuItem>
                    <MenuItem value={1}>{languageContext.get(this.props.language).afternoon}</MenuItem>
                </Select>
                </div>
                <Button className={classes.button} onClick={this.handleSubmit} color="primary" variant="contained" component={Link} to={"/"+this.props.itineraryType}>{languageContext.get(this.props.language).finished}</Button>
                </Grid>
                <Grid item xs={false} sm={4} md={7} elevation={6} className={classes.leftPart}/>
                </Grid>
            </div>    
        );
    }
};

const mapStateToProps = (state) => {
    return ({
        userId:state.user.userId,
        itineraryType: state.itineraries.itineraryType,
        language:state.user.language
    });
}

export default connect(mapStateToProps,{changePage })(withStyles(styles)(UserPreferences));