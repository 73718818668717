import React from 'react'
import TwitterLogin from "react-twitter-login";
import { signIn } from '../action/authActions';
import { connect } from 'react-redux'
import { TwitterLoginButton } from "react-social-login-buttons";
import { makeStyles } from '@material-ui/core/styles';
import { configData } from '../configData'




class TwitterAuth extends React.Component{

    authHandler = (err,data) => {
        if(err === undefined){
            var userName = data.screen_name
            var userImage = 'defaultImage'
            var userEmail = userName+'@twitteremail.assd'
            this.props.signIn(userName,userEmail,userImage,configData.twitter)
        }
    }
    render(){
        return (
            <TwitterLogin
                authCallback={this.authHandler}
                consumerKey="hFSQc5wO92cyfjIqOzhx09QRv"
                consumerSecret="8sC5zdOycKHzOk1MKsR61jK8qmQ0L0lj8MWCsUzw1mbj39VYFz"
                children = {<TwitterLoginButton/>}
                className = {this.props.classes.twitterButton}
            />
        );
    }
}

export default connect(null,
    {
        signIn
    })(TwitterAuth);