import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { connect } from 'react-redux'
import { fetchItinerary, changeItineraryType } from '../action/itineraryAction'
import { changePage } from '../action/changePageAction'
import { withStyles, AppBar } from '@material-ui/core';
import { configData } from '../configData'
import { Link } from 'react-router-dom';
import userPlaceHolder from '../restUtilities/userRest/userPlaceHolder';
import { languageContext } from '../languageContext';







const styles = {
    root: {
        width: '100%',
        
               
      },
}

class ItinerariesSelectorTab extends React.Component{
  
    constructor(){
        super();
        this.state = { itineraryType: configData.suggestedItinerary}
    }
    handleChange = (event, newValue) => {
        //this.setState({itineraryType:newValue});
        this.props.changeItineraryType(newValue);
    };

    componentDidMount(){
        this.setState({itineraryType: this.props.itineraryType});
    }
    handlePageChange = page => {
        this.props.changePage(page);
        
    }
    render(){
            const { classes } = this.props;
            return (
                            <AppBar position="static" style={{top:0,left:0}}>
                                <BottomNavigation value={this.props.itineraryType} onChange={this.handleChange} className={classes.root}>
                                    <BottomNavigationAction label={languageContext.get(this.props.language).suggested} onClick={()=>this.handlePageChange(configData.suggestedItinerary)} value={configData.suggestedItinerary} icon={<RestoreIcon />} component={Link} to="/suggested"/>
                                    <BottomNavigationAction label={languageContext.get(this.props.language).nearest} onClick={()=>this.handlePageChange(configData.nearestItinerary)} value={configData.nearestItinerary} icon={<LocationOnIcon />} component={Link} to="/nearest"/>
                                    <BottomNavigationAction label={languageContext.get(this.props.language).favourite} onClick={()=>this.handlePageChange(configData.favouriteItinerary)} value={configData.favouriteItinerary} icon={<FavoriteIcon /> } component={Link} to="/favourite" />
                                </BottomNavigation>
                            </AppBar>
                    
                
                    
            );
    }
}

const mapStateToProps = (state) => {
     return ({
         
         itineraryType: state.itineraries.itineraryType,
         language: state.user.language
     })
}
export default connect(
    mapStateToProps,
    {
        fetchItinerary,
        changeItineraryType,
        changePage
    }
)(withStyles(styles)(ItinerariesSelectorTab))