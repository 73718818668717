import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polyline} from 'google-maps-react';
import { Typography, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import { Link } from 'react-router-dom';
import InfoWindowEx from './InfoWindowEx';
import { withRouter } from 'react-router-dom'

class WithMarkers extends React.Component {
    state = {
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      idActive:null
    };
    /*
    onInfoWindowOpen(props, e) {
      const button = (
        <div>
          <Typography variant="h6">{this.state.selectedPlace.name}</Typography>
          <IconButton  aria-label="info" onClick={()=>{
              this.props.history.push('/poiReview/'+this.state.idActive);

          }}
          >
              <InfoIcon />
          </IconButton>
        </div>

      );
      ReactDOM.render(React.Children.only(button), document.getElementById("iwc"));
    }
    */
    onMarkerClick = (props, marker,id) =>{
      this.setState({
        activeMarker: marker,
        selectedPlace: props,
        showingInfoWindow: true,
        idActive:id
      });
    }
  
    onInfoWindowClose = () =>
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  
    onMapClicked = () => {
      if (this.state.showingInfoWindow)
        this.setState({
          activeMarker: null,
          showingInfoWindow: false
        });
    };
    onMapReady = (mapProps, map) => {
        this.map = map;
    
        window.onresize = () => {
          const currCenter = map.getCenter();
          this.props.google.maps.event.trigger(map, 'resize');
          map.setCenter(currCenter);
        };
      };
    mapStyles={ position: "relative", width: "90%", height: 300, marginLeft:'5%' };
    render() {
        
      if (!this.props.loaded) return <div>Loading...</div>;
  
      return (
            
        <Map
            className="map"
            google={this.props.google}
            onClick={this.onMapClicked}
            containerStyle={this.mapStyles}
            zoom={6}
            initialCenter={this.props.coordinate[0]}
        >
            {
                this.props.pois.map( (poi)=>{
                    return(
                        <Marker
                        name={poi.name}
                        onClick={(props,marker)=>this.onMarkerClick(props,marker,poi.id)}
                        position={{ lat: poi.lat, lng: poi.lng }}
                        />
                    )
                })
            }
            

            <InfoWindowEx
            marker={this.state.activeMarker}
            onClose={this.onInfoWindowClose}
            visible={this.state.showingInfoWindow}
            
            >
                <div style={{textAlign:'center'}}>
                    <Typography variant="h6">{this.state.selectedPlace.name}</Typography>
                    <IconButton  aria-label="info" onClick={()=>{
                        this.props.history.push('/poiDetail/'+this.state.idActive);
          
                    }}

                    >
                        <InfoIcon />
                    </IconButton>
                </div>
            </InfoWindowEx>
            <Polyline
            fillColor="#0000FF"
            fillOpacity={0.35}
            path={this.props.coordinate}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
            />
        </Map>
        
        
      );
    }
  }
  
  
 
export default withRouter(GoogleApiWrapper({
  apiKey: 'AIzaSyDjqYV7A3byMJppsiEDPl1vO3ngGWrAAAY',
  
})(WithMarkers))

/*
<Marker
        name="Parco Robinson"
        onClick={this.onMarkerClick}
        position={{ lat: 37.778519, lng: -122.40564 }}
        />

        <Marker
        name="Valuetech"
        onClick={this.onMarkerClick}
        position={{ lat: 37.759703, lng: -122.428093 }}
        />

        <Marker name="Rende" onClick={this.onMarkerClick} 
        position={{ lat: 37.759703, lng: -122.44564 }}
        />
*/