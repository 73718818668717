const configData =  {
    suggestedItinerary:'suggested',
    nearestItinerary:'nearest',
    favouriteItinerary:'favourite',
    google:'google',
    facebook:'facebook',
    twitter:'twitter',
    itineraryUrl:'/itinerary',
    assd_baseUrl:'https://assd-server.vtserver.eu',
    favoriteUrl:'/favorite',
    visitedUrl:'/visited',
    chronologyPage:'chronologyPage',
    poiUrl:'/poi',
    getLanguageUrl:'/language',
    italian:'IT',
    english:'EN'
}

export { configData };