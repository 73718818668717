import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux'
import AutoSuggest from 'react-autosuggest'
import { Popover, Backdrop, Dialog, DialogActions, Button } from '@material-ui/core';
import ItinerariesCard from './ItinerariesCard/ItinerariesCard';
import { Link } from 'react-router-dom';
import { configData } from '../configData'
import { languageContext } from '../languageContext';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display:"none"
  },
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    [theme.breakpoints.up('xs')]: {
      width:'auto',
      height:50
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
    [theme.breakpoints.up('xs')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
      padding:0,
      

    },
  },
  navbar: {
      backgroundColor: '#1E90FF',
      zIndex: theme.zIndex.drawer + 1,
  },
  avatar: {
    marginRight: 10,
  },
  container: {
    paddingBottom:20,
    border: '1px solid #1E90FF',
    borderRadius:30,
    marginTop:40
}
});


class Navbar extends React.Component {
 constructor(){
   super();
   this.state = {
    openBox:false,
    filteredItineraries:[],
  };
  
 }

handleSearchInput = (newValue) =>{
  var filteredItineraries = this.props.itineraries.filter( (it)=>{
    
    var check = true;
    newValue.toUpperCase().split(" ").map( (value)=>{
      check = check && (it.name.toUpperCase().includes(value) || it.description.toUpperCase().includes(value) );
    })
    
    return check;
  })
  this.setState({filteredItineraries:filteredItineraries});
}
render() {
  const { classes } = this.props;
  return (
    <div className={classes.root} >
      <AppBar position="fixed">
        <Toolbar className={classes.navbar}>
          <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.props.handleDrawerToggle}
              className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Avatar className={classes.avatar} alt="user image" src= {this.props.userImage} />
          <Typography className={classes.title} variant="h6" noWrap>
            {this.props.userName}
          </Typography>

          <div className={classes.search}>
            <IconButton onClick={()=>this.setState({openBox:true})}  
              disabled={ this.props.page === configData.suggestedItinerary || this.props.page ===configData.nearestItinerary || this.props.page ===configData.favouriteItinerary|| this.props.page ===configData.chronologyPage?false:true} 
            >
                <SearchIcon style={{color:'white'}}/>
            </IconButton>
            <InputBase
              placeholder={languageContext.get(this.props.language).search}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(event)=>this.handleSearchInput(event.target.value)}
              onKeyDown={(pressed)=> 
                          {
                            if(pressed.keyCode===13){
                              this.setState({openBox:true})
                            }
                          }
                        }
              disabled={ this.props.page === configData.suggestedItinerary || this.props.page ===configData.nearestItinerary || this.props.page ===configData.favouriteItinerary || this.props.page ===configData.chronologyPage?false:true
                        }
            />
          </div>
        </Toolbar>
      </AppBar>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={this.state.openBox}
        onClose={()=>{}}
        aria-labelledby="max-width-dialog-title"
        style={{textAlign:"center"}}
      >
      <div style={{padding:70}} >
        {
          this.state.filteredItineraries.length?
            this.state.filteredItineraries.map( (it)=>{
              return <div className={classes.container} key={it.id}>
                    <div style={{padding:15}} >
                      <Typography component="Subtitle1" variant="Subtitle1" >
                        {it.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p"  >
                            {it.description}
                      </Typography>
                      <Link to = {"/itinerarydetails/" + it.id}>
                          <IconButton className={classes.marginLeftAuto} aria-label="info" onClick={()=>this.setState({openBox:false})}><InfoIcon />
                          </IconButton>
                      </Link>
                    </div>
                  </div>
            }):
            <Typography component="Subtitle1" variant="Subtitle1" >
                    {languageContext.get(this.props.language).no_search}
            </Typography>
        }
      </div>
          <DialogActions style={{position:'fixed'}}>
            <Button  color="primary" onClick={()=>this.setState({openBox:false})}>
              {languageContext.get(this.props.language).close}
            </Button>
          </DialogActions>
      </Dialog>
    </div>
  );
  }
}

const mapStateToProps = (state) => {
  return(
    {
      userName: state.user.userName ,
      userImage: state.user.userImage,
      itineraries:state.itineraries.itineraries,
      page:state.page,
      language:state.user.language
    }
  )
}

export default connect(mapStateToProps)(withStyles(styles)(Navbar));



