import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import placesPlaceHolder from '../restUtilities/placesRest/placesPlaceHolder';
import userPlaceHolder from '../restUtilities/userRest/userPlaceHolder';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { browserHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import { Popover, Backdrop, Dialog, DialogActions} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { changePage } from '../action/changePageAction'
import { languageContext } from '../languageContext';

const styles = theme => ({
    title: {
        textAlign: 'center',
        color: '#1E90FF',
        marginBottom: theme.spacing(1),
        paddingTop: '15px'
    },
    subTitle: {
        textAlign: 'center',
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        paddingTop: '10px',
        paddingBottom: '15px'
    },
    root:{
        textAlign: 'center'
    },
    sameLine: {
        padding: '10px'
    },
    item: {
        color: '#1E90FF',
        fontWeight: 'bold'
    },
    button: {
        marginBottom: '10px',
        marginTop: '15px'
    },
    container: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: theme.spacing(15),
        marginRight: theme.spacing(15),
        borderRadius: '15px 50px',
        border: '1px solid #1E90FF'
    },
});

var years = [];
for(var i = 1910; i < 2011; i++) {
    years.push(i);
}

var members = [];
for(var i = 1; i <= 10; i++) {
    members.push(i);
}

class UserDataConfiguration extends React.Component {

    state={userData : {}, studyLevel : [], occupations : [], countries : [], regions : [], province: [] , selectedCountry: null, selectedRegion: null , popup: false, popupError: false}

    onCountryChange = async (event,value) => {
        this.input.country = event.target.value;
        this.setState({selectedCountry:event.target.value})
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.country = event.target.value;                     
            return { userData };                                 
          })
        if(event.target.value === 'Italy') {
            const response = await placesPlaceHolder.get("/Italy");
            this.setState({regions:response.data});
        } else if (event.target.value != 'Italy') {
            this.setState(prevState => {
                let userData = Object.assign({}, prevState.userData); 
                userData.region = null;        
                userData.province = null;          
                return { userData };                                 
              })
        }
    }

    onRegionChange = async (event,value) => {
        this.input.region = event.target.value;
        this.setState({selectedRegion:event.target.value})
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.region = event.target.value;                     
            return { userData };                                 
          })
        const response = await placesPlaceHolder.get("/Italy/" + event.target.value);
        this.setState({province:response.data});
    }

    onProvinceChange = async (event,value) => {
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.province = event.target.value;                     
            return { userData };                                 
          })
        this.input.province = event.target.value;
    }

    onOccupationChange = (event,value) => {
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.occupation = event.target.value;                     
            return { userData };                                 
          })
        this.input.occupation = event.target.value;
    }

    onStudyChange = (event,value) => {
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.study_level = event.target.value;                     
            return { userData };                                 
          })
        this.input.study_level = event.target.value;
    }

    onYearChange = (event,value) => {
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.birth_year = event.target.value;                     
            return { userData };                                 
          })
        this.input.birth_year = event.target.value;
    }

    onSexChange = (event,value) => {
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.sex = event.target.value;                     
            return { userData };                                 
          })
        this.input.sex = event.target.value;
    }

    onMemberChange = (event,value) => {
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.travel_members_number = event.target.value;                     
            return { userData };                                 
          })
        this.input.travel_members_number = event.target.value;
    }

    onTravelChange = (event,value) => {
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.travel_group_type = event.target.value;                     
            return { userData };                                 
          })
        this.input.travel_group_type = event.target.value;
    }

    handleSubmit = async () => {
        if(this.state.userData.birth_year == null || this.state.userData.sex == null || 
            this.state.userData.country == null || (this.state.userData.country === 'Italy' && this.state.userData.province == null && this.state.userData.region == null) || this.state.userData.study_level == null ||
             this.state.userData.occupation == null || this.state.userData.travel_group_type == null
             || this.state.userData.travel_members_number == null) {
                this.setState({popupError:true});
        }
        else {
            await userPlaceHolder.put("/" + this.props.userId,
                this.state.userData
            );
           this.setState({popup:true});
        }
    }

    componentDidMount = async () => {
        this.props.changePage("UserDataConfiguration");
        const response = await placesPlaceHolder.get();
        const responseOccupation = await userPlaceHolder.get("/occupation/"+this.props.language)
        const responseStudy = await userPlaceHolder.get("/study/"+this.props.language);
        const responseData = await userPlaceHolder.get("/" + this.props.userId);
        this.setState({userData :responseData.data, countries:response.data,occupations:responseOccupation.data,studyLevel:responseStudy.data});
        if(this.state.userData.country === 'Italy') {
            const response = await placesPlaceHolder.get("/Italy");
            this.setState({regions:response.data});
            const response2 = await placesPlaceHolder.get("/Italy/" + this.state.userData.region);
            this.setState({province:response2.data});
        }
    }

    input = {
        birth_year:null,
        sex:null,
        country:null,
        region:null,
        province:null,
        study_level:0,
        occupation:0,
        travel_members_number:0,
        travel_group_type:null
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={this.state.popup}
                onClose={()=>{}}
                aria-labelledby="max-width-dialog-title"
                style={{textAlign:"center"}}  
            >
            <Alert
                variant="filled" 
                severity="success"
                action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    this.setState({popup:false});
                    this.props.changePage('suggested');
                    this.props.history.push('/suggested');
                }}
            >
              <ArrowForwardIosIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {languageContext.get(this.props.language).data_updated}
        </Alert>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={this.state.popupError}
                onClose={()=>{}}
                aria-labelledby="max-width-dialog-title"
                style={{textAlign:"center"}}  
            >
            <Alert
                variant="filled" 
                severity="error"
                action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    this.setState({popupError:false});
                }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {languageContext.get(this.props.language).all_field_error}
        </Alert>
            </Dialog>
            <Typography variant="h4" className={classes.title}> {languageContext.get(this.props.language).user_data} </Typography>
                <Typography className={classes.subTitle}> 
                    {languageContext.get(this.props.language).user_data_sub} 
                </Typography>   
            
            <div className={classes.container}>
            <div className={classes.sameLine}>
            <div><Typography className={classes.item}>{languageContext.get(this.props.language).birth_date} </Typography></div>
            <div className={classes.test}>
            <Select
                labelId="born-date"
                id="born-date"
                onChange = {this.onYearChange}
                value = {this.state.userData.birth_year ? this.state.userData.birth_year : null} 
            >
                
                {years.map((year) =>
                    <MenuItem value={year}>{year}</MenuItem>
                )}
            </Select>
            </div>
            </div>
            <div className={classes.sameLine}>
            <div><Typography className={classes.item}>{languageContext.get(this.props.language).sex}</Typography></div>
            <div>
            <Select 
                value = {this.state.userData.sex ? this.state.userData.sex : null}
                labelId="sex"
                id="sex"
                onChange = {this.onSexChange}
                
            >
            <MenuItem value={'M'}>{languageContext.get(this.props.language).male}</MenuItem>
            <MenuItem value={'F'}>{languageContext.get(this.props.language).female}</MenuItem>
            <MenuItem value={'*'}>{languageContext.get(this.props.language).not_specified}</MenuItem>
            </Select>
            </div>
            </div>
            <div className={classes.sameLine}>
            <div><Typography className={classes.item}>{languageContext.get(this.props.language).country}</Typography></div>
            <div>
            <Select
                labelId="country"
                id="country"
                onChange = {this.onCountryChange}
                value={this.state.userData.country ? this.state.userData.country : null}
            >
            {this.state.countries.map((country) =>
                    <MenuItem value={country}>{country}</MenuItem>
            )}
            </Select>
            </div>
            </div>
            {this.state.userData.country === 'Italy' ? (
                <div className={classes.sameLine}>
                <div><Typography className={classes.item}>{languageContext.get(this.props.language).region}</Typography></div>
                <div>
                <Select
                    labelId="region"
                    id="region"
                    onChange = {this.onRegionChange}
                    value={this.state.userData.region ? this.state.userData.region : null}
                >
                    {this.state.regions.map((region) =>
                    <MenuItem value={region}>{region}</MenuItem>
                )}
                </Select>
                </div>
                </div>
            ) : (
                <div></div>
            )}
            {this.state.userData.country==='Italy' ? (
                <div className={classes.sameLine}>
                <div><Typography className={classes.item}>{languageContext.get(this.props.language).province}</Typography></div>
                <div>
                <Select
                    labelId="province"
                    id="province"
                    onChange = {this.onProvinceChange}
                    value={this.state.userData.province ? this.state.userData.province : null}
                >
                    {this.state.province.map((p) =>
                    <MenuItem value={p}>{p}</MenuItem>
                )}
                </Select>
                </div>
                </div>
            ) : (
                <div></div>
            )}
            <div className={classes.sameLine}>
            <div><Typography className={classes.item}>{languageContext.get(this.props.language).occupation}</Typography></div>
            <div>
                <Select
                    labelId="occupation"
                    id="occupation"
                    onChange = {this.onOccupationChange}
                    value={this.state.userData.occupation ? this.state.userData.occupation : null}
                >
                    {this.state.occupations.filter(o => o.code != 0).map((o) =>
                    <MenuItem value={o.code}>{o.nome}</MenuItem>
                )}
                </Select>
                </div>
                </div>
                <div className={classes.sameLine}>
                <div><Typography className={classes.item}>{languageContext.get(this.props.language).study_level}</Typography></div>
                <div>
                <Select
                    labelId="studylevel"
                    id="studylevel"
                    onChange = {this.onStudyChange}
                    value={this.state.userData.study_level ? this.state.userData.study_level : null}
                >
                    {this.state.studyLevel.filter(s => s.code != 0).map((s) =>
                    <MenuItem value={s.code}>{s.nome}</MenuItem>
                )}
                </Select>
                </div>
                </div>
                <div className={classes.sameLine}>
                <div><Typography className={classes.item}>{languageContext.get(this.props.language).travel_members_number}</Typography></div>
                <div>
            <Select
                labelId="members"
                id="members"
                onChange = {this.onMemberChange}
                value={this.state.userData.travel_members_number ? this.state.userData.travel_members_number : null}
            >
                {members.map((m) =>
                    <MenuItem value={m}>{m}</MenuItem>
                )}
            </Select>
            </div>
            </div>
            <div className={classes.sameLine}>
            <div><Typography className={classes.item}>{languageContext.get(this.props.language).travel_group_type}</Typography></div>
            <div>
            <Select
                labelId="members"
                id="members"
                onChange = {this.onTravelChange}
                value={this.state.userData.travel_group_type ? this.state.userData.travel_group_type : null}
            >

                <MenuItem value={'FAMILY_TRIP'}>{languageContext.get(this.props.language).family_trip}</MenuItem>
                <MenuItem value={'FRIENDS_TRIP'}>{languageContext.get(this.props.language).friend_trip}</MenuItem>
                <MenuItem value={'INDIVIDUAL_TRIP'}>{languageContext.get(this.props.language).individual_trip}</MenuItem>
                <MenuItem value={'COUPLE_TRIP'}>{languageContext.get(this.props.language).couple_trip}</MenuItem>
            </Select>
            </div>
            </div>
            <Button className={classes.button} onClick={this.handleSubmit} color="primary" variant="contained">
                {languageContext.get(this.props.language).send_data}
            </Button>
            </div>
            </div>
            );
        }
};

const mapStateToProps = (state) => {
    return ({
        userId:state.user.userId,
        itineraryType: state.itineraries.itineraryType,
        language: state.user.language
    });
}

export default connect(mapStateToProps,{changePage })(withStyles(styles)(UserDataConfiguration));