import { configData } from '../configData'
const INITIAL_STATE = {
    itineraryType: configData.suggestedItinerary,
    itineraries : []
}

const fetchItineraryReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'FETCH_ITINERARY':
            return { ...state, itineraryType:action.payload.type, itineraries:action.payload.itineraries };
            
        
        case 'CHANGE_ITINERARY_TYPE':
            return { ...state, itineraryType:action.payload.type}
        case 'FETCH_VISITED':
            return { ...state, itineraries:action.payload.data}
        default:
            return state;




    }
    
}

export default fetchItineraryReducer;