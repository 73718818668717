import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import Box from '@material-ui/core/Box';
import useStyles from './VisitedItinerariesChronologyCard.style.js';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Rating from '@material-ui/lab/Rating';
import { languageContext } from '../../languageContext.js';

const VisitedItinerariesChronologyCard = props => {
    const [value, setValue] = React.useState(props.user_vote);
    const classes = useStyles();

    const handlePoiList = (list) => {
        return list.map( (item, index) => {
            return (
                <a>
                    <a className={classes.bold}>{index+1}</a>- <a className={classes.bold}>{item.short_description}</a>, Score: {item.score === -1 ? 0 : item.score.toFixed(1)},   
                    <Rating name="disabled" value={item.user_vote} disabled size="small" className={classes.marginRightRating}/>
                </a>
            );
        });
    }
  
    return (
            <Card className={classes.root}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5" className={classes.marginBottom}>
                        {props.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.marginBottom}>
                            {props.description}
                        </Typography>
                        <Typography variant="body2" color="primary" component="p">
                            {languageContext.get(props.language).duration+":"} {props.duration} {languageContext.get(props.language).day_s}
                        </Typography>
                        <Typography variant="body2" color="primary" component="p">
                            {languageContext.get(props.language).category+":"} {props.category}
                        </Typography>
                    </CardContent>

                    <CardContent className={classes.content}>
                        <Typography variant="body2" color="primary" component="p">
                            {languageContext.get(props.language).visited_pois}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.marginBottom}>
                            {handlePoiList(props.pois) }
                        </Typography>
                    </CardContent>

                    <div className={classes.controls}>
                        <Box component="fieldset" borderColor="transparent" className={classes.marginLeftAuto}>
                            <Typography className={classes.marginLeft20} component="legend">Punti: {props.score === -1 ? 0 : props.score}</Typography>
                            <Rating name="disabled" value={value} disabled />
                        </Box>
                        <Link to = {"/itinerarydetails/" + props.id} className={classes.marginLeftAuto}>
                            <IconButton  aria-label="info">
                                <InfoIcon />
                            </IconButton>
                        </Link>
                    </div>
                </div>
            </Card>
    );
}

const mapStateToProps = (state) => {
    return ({
        userId:state.user.userId,
        language: state.user.language
    });
}

export default connect(mapStateToProps)(VisitedItinerariesChronologyCard);