import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import background from './images/landingBackground.jpg'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { languageContext } from '../languageContext';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage:`url(${background})`,
        height: '87.5vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        flex:1,
        justifyContent:'center',
        alignContent:'center',
        
        
    },
    content: {
        height: '87.5vh',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        justifyContent:'center',
        alignContent: 'center',
        flexDirection:'column',
        justifyItems:'center',
        alignItems:'center'
    }
   
}));

const LandingPage = props => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div>
                    <Typography variant="h2" style={{color:'white',fontWeight: "bold", heght:'100%'}}>
                        {languageContext.get(props.language).discover}
                    </Typography>
                </div>

            <div style={{paddingTop:20, width:'95%',alignItems:'center',heght:'100%',textAlign:'center'}}>
                <Typography variant="h5" style={{color:'white'}}>
                    {languageContext.get(props.language).disc_message}
                </Typography>
            </div>
            <div style={{paddingTop:20, width:'30%'}}>
            <Button variant="contained" color="primary" style={{width:'100%'}} component={Link} to="/configuration/categories">
                {languageContext.get(props.language).next}
            </Button>
            </div>
            </div>
            
        </div>
    )
}

const mapStateToProps = state =>{
    return({
        language:state.user.language
    })
}
export default connect(mapStateToProps)(LandingPage);