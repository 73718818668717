import React from 'react'
import { connect } from 'react-redux'
import { signIn, signOut } from '../action/authActions'
import { GoogleLoginButton } from "react-social-login-buttons";
import { configData } from '../configData'


class GoogleAuth extends React.Component {

    constructor(){
        super();
        this.state = {_loading:true}
    }
    componentDidMount(){
        
        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                clientId:
                    '886631787561-od4ul6ihb6j4urns9rq458n3ljrfn2a5.apps.googleusercontent.com',
                scope: 'email'

            }).then( () => {
                this.setState({_loading:false});
                this.auth = window.gapi.auth2.getAuthInstance();
                this.auth.isSignedIn.listen( this.onAuthStateListener );
                if(this.auth.isSignedIn.get() === true){
                    var user = this.auth.currentUser.get().getBasicProfile();
                    var isGoogle = true;
                    this.props.signIn(user.getName(),user.getEmail(),user.getImageUrl(),configData.google);
                }
            });
        });

    }

    onAuthStateListener = () => {
        if(this.auth.isSignedIn.get() === true){
            var user = this.auth.currentUser.get().getBasicProfile();
            var isGoogle = true;
            this.props.signIn(user.getName(),user.getEmail(),user.getImageUrl(),configData.google);
        }
        else{
            this.props.signOut();
        }
    }
    onClickLogin = () =>{
        if(!this.state._loading)
            this.auth.signIn();
    }

    
    render(){
        
        
       
        return <GoogleLoginButton onClick={this.onClickLogin} />

        
        
        
    }
}

export default connect(
    null,
    {
        signOut,
        signIn
    })(GoogleAuth);