import { configData } from "../configData";

var initialPage=configData.suggestedItinerary

const changePageReducer = (state=initialPage,action)=>{
    switch(action.type){
        case "CHANGE_PAGE":
            return action.payload;
        default:
            return state;
    }
}

export default changePageReducer;