import React from 'react';
import { fetchVisitedItinerary } from  '../action/itineraryAction'
import { connect } from 'react-redux';
import { CircularProgress, withStyles } from '@material-ui/core';
import VisitedItinerariesChronologyCard from './VisitedItinerariesChronologyCard/VisitedItinerariesChronologyCard';
import Typography from '@material-ui/core/Typography';
import { changePage } from '../action/changePageAction'
import { configData } from '../configData';
import { languageContext } from '../languageContext'

const useStyles = theme=> ({
    root: {
          
        display:'flex',
        flex:1,
        justifyContent:'center',
        marginTop:20
  
    },
    
});
class ItineraryCronology extends React.Component{
    constructor(){
        super();
        this.state = {_loading:true};
    }
    componentDidMount = async ()  =>{
        this.props.changePage(configData.chronologyPage)
        await this.props.fetchVisitedItinerary(this.props.user.userId);
        this.setState({_loading:false});
    }
    render(){
        const { classes } = this.props;
        return (
            <div style={{paddingBottom:50}}>
                { this.state._loading?
                    <div>
                        <div className={classes.root}>
                            <h2>
                                {languageContext.get(this.props.language).loading}
                            </h2>
                        </div>
                        <div className={classes.root}>
                        
                            
                            <CircularProgress />
                        
                        </div>
                    </div>: null
                }
                {
                    !this.state._loading ?
                    <div>
                        <div className={classes.root} >
                        <Typography variant="h4" style={{color:'#1E90FF'}}>
                            {languageContext.get(this.props.language).visited}
                        </Typography>
                        </div>
                    {
                    this.props.itineraries.map( (itinerary) => 
                            <div key={itinerary.id}>
                                <VisitedItinerariesChronologyCard
                                        id= {itinerary.id}
                                        name= {itinerary.name}
                                        description={itinerary.description}
                                        duration= {itinerary.duration}
                                        category= {itinerary.category}
                                        pois= {itinerary.pois}
                                        score= {itinerary.score}
                                        user_vote= {itinerary.user_vote}
                                />
                            </div>
                    )
                    }
                    </div>
                    : null            
                }
                {    
                    !this.state._loading && !this.props.itineraries.length?
                    <div className={classes.root}>
                        <h2>
                            {languageContext.get(this.props.language).no_itineraries}
                        </h2>
                    </div>:null
                }
                    
                
            </div>
        );
    }
};

const mapStateToProps = state =>{
    return({
        itineraries:state.itineraries.itineraries,
        user:state.user,
        language: state.user.language
    });
}
export default connect(mapStateToProps,{
    fetchVisitedItinerary,
    changePage
})(withStyles(useStyles)(ItineraryCronology));