const INITIAL_STATE = {
    isSignedIn:false,
    userEmail:null,
    userName:null,
    userImage:null,
    userId:null,
    social:null,
    firstAccess:null,
    language:null
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case 'SIGN_IN':
            return { ...state, isSignedIn:true, userName:action.payload.userName,userEmail:action.payload.userEmail, userImage:action.payload.userImage,userId:action.payload.userId, social:action.payload.social, firstAccess:action.payload.firstAccess, language:action.payload.language };
        case 'SIGN_OUT':
            return { ...state, isSignedIn:false };
        case 'CHANGE_LANGUAGE':
            return { ...state, language:action.payload.language}
        default:
            return state;



    }
    
}

export default authReducer;