import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import { render } from 'react-dom';
import { ChatFeed, ChatBubble, BubbleGroup, Message } from 'react-chat-ui';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import chatbotPlaceholder from '../restUtilities/chatbotRest/chatbotPlaceholder';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { languageContext } from '../languageContext';
import axios from 'axios'
import { configData } from '../configData'

const styles = theme => ({
   container: {
      marginTop: theme.spacing(10),
      marginRight: '20px',
      marginLeft: '20px',
   },
   inputChat: {
      borderRight : '20px solid white',
      borderLeft : '20px solid white',
      textAlign:'center',
      position: 'fixed',
      bottom: '0',
      marginBottom: theme.spacing(3),
      width: '100%',
      ['@media (min-width:768px)']: {
        paddingRight:'240px'
      },
   }
});

class Chatbot extends React.Component {

    constructor() {
        super();
        this.state = {messages: [], toSend: ' ', lastMessageType : undefined, buttons : [], chatDimension : undefined, chinese : false, enabledChinese: false};
      }
      
      componentDidUpdate = () => {
        if(this.props.language != this.state.lastLanguage) {
           this.componentDidMount()
           this.setState({lastLanguage : this.props.language,messages: [], toSend: ' ',lastMessageType : undefined, buttons : []});
        }
      }

      componentDidMount  = async () => {
        this.setState({lastLanguage : this.props.language});
        var height = window.screen.height;
        if(height >= 900) {
          this.setState({chatDimension : 400});
        } else if(height >= 800 && height < 900) {
          this.setState({chatDimension : 465});
        } else if (height >= 700 && height < 800) {
          this.setState({chatDimension : 315});
        } else if (height >= 650 && height < 700) {
          this.setState({chatDimension : 310});
        } else if (height >= 600 && height < 650) {
          this.setState({chatDimension : 260});
        } else {
          this.setState({chatDimension : 150});
        }

        let chineseStatus= await chatbotPlaceholder.get('/chineseStatus');
        this.setState({enabledChinese: chineseStatus.data});

        let response;
        if(this.state.chinese === true){
          response = await axios.post(
            configData.assd_baseUrl+'/chatbot/sendChinese',
            { user_id: this.props.userId, textMessage: '_initchat' },
            { headers: { 'Content-Type': 'application/json' } }
          )
        }else{
          this.props.language === 'IT' 
          ? response = await chatbotPlaceholder.get('/sendMessage/' + this.props.userId + '/_initchat/IT')
          : response = await chatbotPlaceholder.get('/sendMessage/' + this.props.userId + '/_initchat/EN');
        }
        let listResponse = response.data._listresponse;
        let buttons = listResponse[0].buttons;
        let buttonsText = [];
        let buttonMappings={};
        buttons.map((b) => {
          buttonsText.push(b.text);
          buttonMappings[b.text]=b.message;
        });
        let text = listResponse[0].text;
        const prevState = this.state;
        const newMessage = new Message({
          id: 1,
          message: text,
          });
          prevState.messages.push(newMessage);
          prevState.lastMessageType = 'BUTTON';
          prevState.buttons = buttonsText;
          prevState.buttonMaps=buttonMappings;
          this.setState(this.state);
      }


      pushMessage = async () => {
        if(this.state.toSend !== ' ') {
            const prevState = this.state;
            const newMessage = new Message({
            id: 0,
            message: prevState.toSend,
            });
            prevState.messages.push(newMessage);
            prevState.lastMessageType = undefined;
            this.setState(this.state);
            let response;
            if(this.state.chinese === true){
              response = await axios.post(
                configData.assd_baseUrl+'/chatbot/sendChinese',
                { user_id: this.props.userId, textMessage: prevState.toSend },
                { headers: { 'Content-Type': 'application/json' } }
              )
            }else{
              this.props.language === 'IT' 
              ? response = await chatbotPlaceholder.get('/sendMessage/' + this.props.userId + '/' + prevState.toSend.trim() + '/IT')
              : response = await chatbotPlaceholder.get('/sendMessage/' + this.props.userId + '/' + prevState.toSend.trim() + '/EN');
            }
            this.setState({toSend : ' '});
            let listResponse = response.data._listresponse;
            listResponse.map((r) => {
              if(r.type === 'TEXT') {
                const prevState = this.state;
                const newMessage = new Message({
                  id: 1,
                  message: r.text,
                });
                prevState.messages.push(newMessage);
                prevState.lastMessageType = undefined;
                this.setState(prevState);
              } else if (r.type === 'BUTTONS') {
                let buttons = r.buttons;
                let buttonsText = [];
                let buttonMappings={};

                buttons.map((b) => {
                  buttonsText.push(b.text);
                  buttonMappings[b.text]=b.message;
                });
                const prevState = this.state;
                const newMessage = new Message({
                  id: 1,
                  message: r.text,
                  });
                prevState.messages.push(newMessage);
                prevState.lastMessageType = 'BUTTON';
                prevState.buttons = buttonsText;
                prevState.buttonMaps=buttonMappings;

                this.setState(prevState);
              }
            })
          }
      }
    
      pushMessageButton = async (testo) => {
        const prevState = this.state;
        const newMessage = new Message({
        id: 0,
        message: testo,
        });
        prevState.messages.push(newMessage);
        prevState.lastMessageType = undefined;
        this.setState(prevState);
        let response;
        if(this.state.chinese === true){
          response = await axios.post(
            configData.assd_baseUrl+'/chatbot/sendChinese',
            { user_id: this.props.userId, textMessage: this.state.buttonMaps[testo] },
            { headers: { 'Content-Type': 'application/json' } }
          )
        }else{
          this.props.language === 'IT' 
          ? response = await chatbotPlaceholder.get('/sendMessage/' + this.props.userId + '/' + testo + '/IT')
          : response = await chatbotPlaceholder.get('/sendMessage/' + this.props.userId + '/' + testo + '/EN');
        }
        this.setState({toSend : ' '});
        let listResponse = response.data._listresponse;
        listResponse.map((r) => {
            if(r.type === 'TEXT') {
              const prevState = this.state;
              const newMessage = new Message({
                id: 1,
                message: r.text,
                });
              prevState.messages.push(newMessage);
              prevState.lastMessageType = undefined;
              this.setState(prevState);
            } else if (r.type === 'BUTTONS') {
              let buttons = r.buttons;
              let buttonsText = [];
              let buttonMappings={};

              buttons.map((b) => {
                buttonsText.push(b.text)
                buttonMappings[b.text]=b.message;
              });
              const prevState = this.state;
              const newMessage = new Message({
                id: 1,
                message: r.text,
                });
              prevState.messages.push(newMessage);
              prevState.lastMessageType = 'BUTTON';
              prevState.buttons = buttonsText;
              prevState.buttonMaps=buttonMappings;

              this.setState(prevState);
            }
          })
      }

      switchChinese = async () => {
        if(this.state.chinese === true)
          await this.setState({chinese : false})
        else
          await this.setState({chinese : true})

          await this.setState({messages: []})

          this.componentDidMount()
      }

      handleChange = (event) => {
          this.setState({toSend: event.target.value});
      }

      render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.container}>
                <div className="chatfeed-wrapper">
                <ChatFeed
                    maxHeight={this.state.chatDimension}
                    messages={this.state.messages}               
                />
                </div>
                </div>
                <div className={classes.inputChat}>
                {this.state.lastMessageType === 'BUTTON' ?
                <div>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {this.state.buttons.map((button) => (
                        <Grid key={Math.random()} item xs={12/this.state.buttons.length}>
                          <Button key={Math.random()} color = 'primary' variant="outlined" fullWidth={true} style={{marginBottom:'5px'}} onClick={() => this.pushMessageButton(button)}>{button}</Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </div> :
                <div/>
                }
                <TextField id="outlined-basic" variant="outlined" 
                    onChange={this.handleChange}
                    value={this.state.toSend}
                    fullWidth={true}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={this.pushMessage}>
                                <SendIcon color='primary'/>
                            </IconButton>
                            {this.state.enabledChinese===true  && this.props.language === 'EN'
                              ?<div>
                                {!this.state.chinese
                                ? <Button onClick={this.switchChinese} variant = 'outlined'>
                                    <img src = {'/images/china-flag-icon-32.png'}></img>
                                </Button>
                                : <Button onClick={this.switchChinese} variant = 'contained'>
                                    <img src = {'/images/china-flag-icon-32.png'}></img>
                                </Button>
                                }
                              </div>
                              :null
                            }
                        </InputAdornment>,
                      }}                     
                    onKeyDown={(pressed)=> 
                        {
                          if(pressed.keyCode===13){
                            this.pushMessage()
                          }
                        }
                      }
                    /> 
                </div>
            </div>
        );
      }   
   
}

const mapStateToProps = (state) => {
  return ({
      userId:state.user.userId,
      language: state.user.language
  });
}

export default connect(mapStateToProps)(withStyles(styles)(Chatbot));