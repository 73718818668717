import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InfoIcon from '@material-ui/icons/Info';
import CommentIcon from '@material-ui/icons/Comment';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import useStyles from './ItinerariesCard.style.js';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ItineraryPlaceHolder from '../../restUtilities/itinerariesRest/itinerariesPlaceHolder';
import { fetchItinerary } from '../../action/itineraryAction/index'
import { configData } from '../../configData'
import { changePage } from '../../action/changePageAction'
import { languageContext } from '../../languageContext'

const ItinerariesCard = props => {
    const [value, setValue] = React.useState(props.user_vote);
    const [favouriteValue, setFavouriteValue] = React.useState(props.marked_as_pref === true ? 1 : 0);
    const classes = useStyles();

    const StyledRating = withStyles({
        iconFilled: {
          color: '#ff6d75',
        },
        iconHover: {
          color: '#ff3d47',
        },
        iconEmpty: {
            color: '#707070',
        },
    })(Rating);

    const handleFavourite = async (newValue) =>{
        setFavouriteValue(newValue);
        var preference;
        if(newValue == 1) {
            preference = true;
        } else {
            preference = false;
        }
        await ItineraryPlaceHolder.put("/" + props.userId + "/" + props.id + "/" + preference)
        if(props.itineraryType === 'favourite') {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(makeRequest);
            }
        }
    }

    const makeRequest = async (position) =>{  
        await props.fetchItinerary(configData.favouriteItinerary,position.coords.latitude,position.coords.longitude,props.userId,null);
    }

    return (
            <Card className={classes.root}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5" className={classes.marginBottom}>
                        {props.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.marginBottom}>
                        {props.description}
                        </Typography>
                        <Typography variant="body2" color="primary" component="p">
                            {languageContext.get(props.language).duration+":"} {props.duration} {languageContext.get(props.language).day_s}
                        </Typography>
                        <Typography variant="body2" color="primary" component="p">
                            {languageContext.get(props.language).category+":"} {props.category}
                        </Typography>
                    </CardContent>

                    <div className={classes.controls}>
                        <IconButton aria-label="add to favorites">
                            <StyledRating 
                                name={props.id.toString()}
                                value={favouriteValue}
                                precision={1}
                                max={1}
                                icon={<FavoriteIcon fontSize="inherit" />}
                                onChange={(event,newValue) => handleFavourite(newValue)}
                            />
                        </IconButton>
                        <IconButton aria-label="share"
                            onClick={()=>props.deleteItinerary(props.id)}
                        >
                            <DeleteForeverIcon />
                        </IconButton>

                        {favouriteValue === 1 ?
                            <Link to = {"/makereview/" + props.id}>
                                <IconButton aria-label="review" onClick={()=>props.changePage("makeReview")}>
                                    <RateReviewIcon />
                                </IconButton>
                            </Link>:
                            <IconButton aria-label="review">
                                <RateReviewIcon style={{ color: 'white' }} />
                            </IconButton>
                        }
                        <Box component="fieldset" borderColor="transparent" className={classes.marginLeftAuto}>
                            <Typography className={classes.marginLeft20} component="legend">{languageContext.get(props.language).points+":"} {props.score === -1 ? 0 : props.score}</Typography>
                            <Rating name="disabled" value={value} disabled />
                        </Box>
                        <Link to = {"/itinerarydetails/" + props.id} className={classes.marginLeftAuto}>
                            <IconButton  aria-label="info">
                                <InfoIcon />
                            </IconButton>
                        </Link>
                        <Link to = {"/reviews/" + props.id}>
                            <IconButton aria-label="review">
                                <CommentIcon />
                            </IconButton>
                        </Link>
                    </div>
                </div>
                <CardMedia
                className={classes.cover}
                image={props.url_image}
                title="Itinerary image"
                />
            </Card>
    );
}

const mapStateToProps = (state) => {
    return ({
        userId:state.user.userId,
        itineraryType: state.itineraries.itineraryType,
        language: state.user.language
    });
}

export default connect(mapStateToProps,{fetchItinerary, changePage})(ItinerariesCard);