import React, { useState } from 'react'
import Language from '../Language'
import { Dialog, DialogTitle, DialogContentText, DialogActions, Button, Slide, DialogContent, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { languageContext } from '../../languageContext';
import { configData } from '../../configData';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const LanguageDialog = props =>{
    const [value,setValue] = useState(props.userLanguage)
    return(
        <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{languageContext.get(props.userLanguage).changeLanguage}</DialogTitle>
        <DialogContent>
            <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={(event)=>setValue(event.target.value)}>
                    <FormControlLabel value={configData.italian} control={<Radio />} label={languageContext.get(props.userLanguage).italian} />
                    <FormControlLabel value={configData.english} control={<Radio />} label={languageContext.get(props.userLanguage).english} />
                </RadioGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.handleClose(value)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
}
export default LanguageDialog 