import React from 'react'
import { connect } from 'react-redux'
import { fetchItinerary } from '../action/itineraryAction'
import { configData } from '../configData'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ItinerariesCard from './ItinerariesCard/ItinerariesCard'
import ItinerarySelectorTab from './ItinerariesSelectorTab'
import ItineraryPlaceHolder from '../restUtilities/itinerariesRest/itinerariesPlaceHolder'
import axios from 'axios'
import { changePage } from '../action/changePageAction'
import { languageContext } from '../languageContext'

const useStyles = theme=> ({
    root: {
          
        display:'flex',
        flex:1,
        justifyContent:'center',
        marginTop:20
  
    },
    
});
class ItineraryNearest extends React.Component {

    constructor(){
        super();
        this.state = {_loading:true,itineraries:[]};
        this.CancelToken = axios.CancelToken;
        this.source = this.CancelToken.source();
    }
    componentDidMount = () =>{
        this.props.changePage(configData.nearestItinerary);
        console.log(this.state._loading);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.makeRequest);
        }
        console.log('ok');
        
    }

    
    makeRequest = async (position) =>{
        
        await this.props.fetchItineraries(configData.nearestItinerary,position.coords.latitude,position.coords.longitude,this.props.user.userId,this.source);
        this.setState({_loading:false,itineraries:this.props.itineraries});
        
    
    }

    deleteItinerary = async itineraryId =>{
        await ItineraryPlaceHolder.delete(itineraryId+"/"+this.props.user.userId);
        
        
        this.setState({itineraries:this.state.itineraries.filter( (it)=>{
            return it.id!==itineraryId;
        })})
        
    }
    
    render(){
        const { classes } = this.props;
        return(
            <div style={{paddingBottom:50}}>
                <ItinerarySelectorTab/>
                { this.state._loading?
                <div>
                    <div className={classes.root}>
                        <h2>
                            {languageContext.get(this.props.language).system_generation}
                        </h2>
                    </div>
                    <div className={classes.root}>
                    
                        
                        <CircularProgress />
                    
                    </div>
                </div>: null
                }
                
                    
                {
                    !this.state._loading?
                    this.state.itineraries.map( (itinerary) => {
                        return (
                            <div key={itinerary.id}>
                                <ItinerariesCard
                                    category = {itinerary.category}
                                    description = {itinerary.description}
                                    duration = {itinerary.duration}
                                    id={itinerary.id}
                                    marked_as_pref= {itinerary.marked_as_pref}
                                    name={itinerary.name}
                                    score= {itinerary.score}
                                    url_image= {itinerary.url_image}
                                    user_vote= {itinerary.user_vote}
                                    deleteItinerary={this.deleteItinerary}
                                />
                            </div>
                        )
                    }):null           
                }
                        {
                            
                            !this.state._loading && !this.state.itineraries.length?
                            <div className={classes.root}>
                                <h2>
                                    {languageContext.get(this.props.language).no_itineraries}
                                </h2>
                            </div>:null
                        }
                    
                
            </div>
                
            
            
        );
    }

    componentWillUnmount = ()=>{
        console.log('Cancello');
        this.source.cancel('Get cancelled');
    }
}

const mapStateToProps = (state) => {

    return (
        {
            itineraryType:state.itineraries.itineraryType,
            itineraries:state.itineraries.itineraries,
            user: state.user,
            language: state.user.language
        }
    );
}
export default connect(mapStateToProps,{
    fetchItineraries: fetchItinerary,
    changePage:changePage
})(withStyles(useStyles)(ItineraryNearest));