import React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import ItinerariesSelectorTab from './ItinerariesSelectorTab';


import ClippedDrawer from './Drawer';


const Dashboard = () => {
    return (
        <div>               
            <ClippedDrawer />
        </div>
    );
}

export default Dashboard;
