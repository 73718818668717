import React from 'react';
import poiPlaceholder from '../restUtilities/poiRest/poiPlaceholder';
import { CircularProgress, Button, Container } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import { changePage } from '../action/changePageAction/index'
import { languageContext } from '../languageContext';

const styles = theme => ({
    sameLine: {
        display: 'inline-block'
    },
    review: {
        border: '1px solid #1E90FF'
    },
    colorText: {
        color: '#1E90FF'
    },
    half: {
        display: 'inline-block',
        
    },
    centerDiv: {
        textAlign: 'center'
    },
    container: {
        marginLeft: theme.spacing(10),
        marginRight: theme.spacing(10),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    username: {
        marginLeft: theme.spacing(1),
        marginTop: '5px',
        marginBottom: '15px'
    }
})

class Reviews extends React.Component {

    state = {reviews : [], isLoading : true, poiName : undefined};

    back = () => {
        this.props.history.goBack()
    }

    componentDidMount = async () => {
        this.props.changePage('poireviews');
        const poiDetails = await poiPlaceholder.get('/' + this.props.userId + '/' + this.props.match.params.id);
        const response =  await poiPlaceholder.get('/review/' + this.props.match.params.id + '/vote/desc');
        this.setState({reviews : response.data.reviews, isLoading : false});
        this.setState({poiName : poiDetails.data.name});
    }

    render() {
        const { classes } = this.props;
        return(
            this.state.isLoading ?
            <div style={{textAlign:'center'}}>
            <h2>{languageContext.get(this.props.language).loading}</h2>
            <CircularProgress />
            </div> :
            <div>
            <IconButton aria-label="back" onClick={this.back}>
                <ArrowBackIosIcon />
            </IconButton>
            <div>
            <Typography variant="h4" style={{marginTop:'20px',marginBottom:'10px',color:'#1E90FF', textAlign:'center'}}>
                {this.state.poiName}
            </Typography> 
            {this.state.reviews.length > 0 ?
                <div className={classes.container}>
                    {this.state.reviews.map((review) =>
                    <div style={{marginBottom: '10px'}} key={review.user_id}> 
                        <Card key={review.user_id}>
                            <CardContent>
                            <div className={classes.centerDiv}>
                            <Rating name="rating" value={review.rating} disabled />
                            </div>
                            <div style={{justifyContent : 'flex-start', display: 'flex'}}>
                            <div>
                                <Avatar src={review.user_image}></Avatar>
                            </div>
                            <div className={classes.username}>
                                <Typography variant="h6" component="h6">
                                    {review.user_name}
                                </Typography>
                            </div>
                            </div>
                            <Typography className={classes.colorText}>
                                {languageContext.get(this.props.language).time_visit+  review.visiting_time+ " minuti"}
                            </Typography>
                            <div style={
                                {textAlign:'right'}
                            }>
                             </div>
                            </CardContent>
                        </Card>
                        </div>
                    )}
            </div> :
            <div>
                <div style={{textAlign:'center'}}>
                   <h2> {languageContext.get(this.props.language).no_reviews} </h2>
                </div>
                </div> }   
            </div>                  
            </div>
            );
    }
}

const mapStateToProps = (state) => {
    return ({
        userId:state.user.userId,
        language: state.user.language
    });
}

export default connect(mapStateToProps,{changePage})(withStyles(styles)(Reviews));