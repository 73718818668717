import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PoiPlaceHolder from '../restUtilities/poiRest/poiPlaceholder';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import {FacebookShareButton,TwitterShareButton,WhatsappShareButton,FacebookIcon,TwitterIcon,WhatsappIcon} from 'react-share';
import Popover from '@material-ui/core/Popover';
import PoiMap from './poiMap/PoiMap';
import PhoneIcon from '@material-ui/icons/Phone';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import RoomIcon from '@material-ui/icons/Room';
import Carousel from 'react-material-ui-carousel';
import { changePage } from '../action/changePageAction'
import { languageContext } from '../languageContext';

const styles = theme => ({
    title: {
        textAlign: 'center',
        color: '#1E90FF',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    subTitle: {
        textAlign: 'center',
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        paddingTop: '10px',
        paddingBottom: '15px'
    },
    centerDiv: {
        textAlign: 'center',
    },
    colorText: {
        color: '#1E90FF',
    },
    duration: {
        textAlign: 'center',
        color: '#1E90FF',
        marginTop: theme.spacing(3)
    },
    sameLine: {
        display:'inline',
        margin: theme.spacing(2)
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    tile: {
        width: '50px !important' ,
    },
    tags: {
        textAlign: 'center',
        color: '#1E90FF',
        margin: theme.spacing(3)
    },
    image: {
        height: '100%',
        width: '100%'
    },
    root: {
        height: '100vh',
    },
});

class poiDetails extends React.Component{
    
    state = {poi:null};

    back = () => {
        this.props.history.goBack()
    }
    componentDidMount = async () => {
        this.props.changePage("PoiDetail");
        const response  = await PoiPlaceHolder('/' + this.props.userId + '/' + this.props.match.params.id);
        console.log(response.data);
        this.setState({poi:response.data});
        
    }

    handleClick = (event) => {
        //setAnchorEl(event.currentTarget);
        this.setState({popover: event.currentTarget})
    };

    handleClose = () => {
        //setAnchorEl(null);
        this.setState({popover: null})
    };

    render() {
        
        const { classes } = this.props;
        return(
            !this.state.poi ?
            <div className = {classes.centerDiv}>
            <h2>Caricamento</h2>
            <CircularProgress />
            </div> :
            <div>
                <div>
                    <IconButton aria-label="favourite" onClick={this.back}>
                        <ArrowBackIosIcon />
                    </IconButton>
                </div>
                <div>               
                    <Typography variant="h4" className= {classes.title} > {this.state.poi.name} </Typography>
                </div>
                {this.state.poi.description!==null?
                    <div>
                        <Typography className={classes.subTitle}> {this.state.poi.description} </Typography>
                    </div>:
                    null
                }
                <div className={classes.centerDiv}>
                    <Rating name="disabled" value={this.state.poi.user_vote === -1 ? 0 : this.state.poi.user_vote} disabled />
                    <Typography component="legend" className={classes.colorText}>{languageContext.get(this.props.language).points+" :"} {this.state.poi.score === -1 ? 0 : this.state.poi.score}</Typography>
                </div>

                <div className={classes.centerDiv}>
                    <Typography component="legend" className={classes.colorText}>{languageContext.get(this.props.language).entry_price} {this.state.poi.total_price}</Typography>
                </div>
                
                <div className={classes.tags}>
                    <Typography component="legend" style={{paddingBottom:10, color:'black'}}><b>{languageContext.get(this.props.language).also_knows_as}</b></Typography>
                    {
                    
                        //Anche conosciuto come
                        this.state.poi.alternative_names.map((tag,i) =>
                            {   if(i<this.state.poi.alternative_names.length-1){
                                    return tag + ", "
                                }
                                return tag
                                
                            }
                        )
                    }
                </div>
                <div className={classes.centerDiv} style={{paddingBottom:10}}>
                    <Link to = {"/poireviews/" + this.state.poi.id} params={{ testvalue: "hello" }}>
                    <IconButton aria-label="reviews">
                        <CommentIcon />
                    </IconButton>
                    </Link>
                    <IconButton aria-label="share" onClick={this.handleClick}>
                        <ShareIcon />
                    </IconButton>
                    <Popover
                        id={1}
                        open={this.state.popover}
                        anchorEl={this.state.popover}
                        onClose={this.handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                        }}
                    >
                        <Typography style={{margin:'20px', marginBottom:'0px', textAlign:'center'}}><b>{languageContext.get(this.props.language).share}</b></Typography>
                        <div style={{textAlign:'center'}}>
                            <FacebookShareButton
                                url='https://assd-webapp.vtserver.eu/'
                                quote={languageContext.get(this.props.language).first_part_share_poi + this.state.poi.name + languageContext.get(this.props.language).second_part_share}
                                style={{margin:'15px'}}
                            >
                            <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                url='https://assd-webapp.vtserver.eu/'
                                title={languageContext.get(this.props.language).first_part_share_poi + this.state.poi.name + languageContext.get(this.props.language).second_part_share}
                                style={{margin:'15px'}}
                            >
                            <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <WhatsappShareButton
                                url='https://assd-webapp.vtserver.eu/'
                                title={languageContext.get(this.props.language).first_part_share_poi + this.state.poi.name + languageContext.get(this.props.language).second_part_share}
                                style={{margin:'15px'}}
                            >
                            <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                    </Popover>
                </div>
                <div>
                    {this.state.poi.tel_number!==null?
                        <div style={{display:'flex',flexDirection: 'row', justifyContent:'center'}}>
                            <PhoneIcon style={{paddingRight:5,color:'#1E90FF'}}/>
                            <Typography style={{paddingRight:5}}>
                                <b>{languageContext.get(this.props.language).phone}</b>
                            </Typography>
                            <Typography style={{color:'#1E90FF'}}>
                                {this.state.poi.tel_number}
                            </Typography>
                        </div>:
                        null
                    }
                    {this.state.poi.opening_info!==null?
                        <div style={{display:'flex',flexDirection: 'row', justifyContent:'center',marginTop:10}}>
                            <QueryBuilderIcon style={{paddingRight:5, color:'#1E90FF'}}/>
                            <Typography style={{paddingRight:5}}>
                                <b>{languageContext.get(this.props.language).timetables}</b>
                            </Typography>
                            <Typography style={{color:'#1E90FF'}}>
                                {this.state.poi.opening_info}
                            </Typography>
                        </div>:
                        null
                    }
                    {this.state.poi.email!==null?
                        <div style={{display:'flex',flexDirection: 'row', justifyContent:'center',marginTop:10}}>
                            <MailOutlineIcon style={{paddingRight:5, color:'#1E90FF'}}/>
                            <Typography style={{paddingRight:5}}>
                                <b>Email:</b>
                            </Typography>
                            <Typography style={{color:'#1E90FF'}}>
                                {this.state.poi.email}
                            </Typography>
                        </div>:
                        null
                    }
                    
                </div>
                <div>
                    {this.state.poi.web_site!==null?
                        <div>
                            <div style={{display:'flex',justifyContent:'center',marginTop:15}}>
                                <WebAssetIcon style={{paddingRight:5, color:'#1E90FF'}}/>
                                <Typography style={{paddingRight:5}}>
                                    <b>WebSite:</b>
                                </Typography>
                                
                            </div>
                            <Typography style={{color:'#1E90FF',textAlign:'center',paddingLeft:200,paddingRight:200}}>
                                {this.state.poi.web_site}
                            </Typography>
                        </div>:
                        null
                    }
                </div>
                <div>
                    <div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:15}}>
                            <RoomIcon style={{paddingRight:5, color:'#1E90FF'}}/>
                            <Typography style={{paddingRight:5}}>
                                <b>Location:</b>
                            </Typography>
                            
                        </div>
                        <Typography style={{color:'#1E90FF',textAlign:'center',paddingLeft:200,paddingRight:200}}>
                            {this.state.poi.place.country+", "+this.state.poi.place.region+", "+this.state.poi.place.name+" ("+this.state.poi.place.province+"), "+this.state.poi.address}
                        </Typography>
                    </div>
                </div>
                {this.state.poi.images.length?
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <div style={{width:'50%',height:'30%'}}>
                            <div style={{marginTop:15,alignContent:'center'}}>
                                <Carousel>
                                    {
                                        this.state.poi.images.map( image => 
                                            <div style={{display:'flex',justifyContent:'center'}}>
                                                <img width={1000} height={500} src={image}></img>
                                            </div> 
                                        )
                                    }
                                </Carousel>
                            </div>
                        </div>
                    </div>:
                    null
                }
                
            </div>
           
        );
        
       
    }
}

const mapStateToProps = (state) => {
    return ({
        userId:state.user.userId,
        language: state.user.language
    });
}

export default connect(mapStateToProps,{
    changePage
})(withStyles(styles)(poiDetails));