import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    marginBottom:{
        marginBottom: 7
    },
    root: {
        display: 'flex',
        marginRight: 50,
        marginLeft: 50,
        marginTop: 50
    },
    details: {
      display: 'flex',
      flex: 0.7,
      flexDirection: 'column',
    },
    marginLeftAuto: {
        marginLeft: 'auto'
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      flex: 0.3
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    marginLeft20: {
        marginLeft: 20
    }
}));