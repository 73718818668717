import React from 'react';
import { CircularProgress, Button, withStyles, Typography, Grid, Paper, IconButton, Select, MenuItem, InputBase, TextField, GridList, GridListTile, GridListTileBar, Checkbox, InputLabel, FormControl, Dialog } from '@material-ui/core';
import { connect } from 'react-redux';
import itinerariesPlaceHolder from '../restUtilities/itinerariesRest/itinerariesPlaceHolder';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close'
import Rating from '@material-ui/lab/Rating';
import DateFnsUtils from '@date-io/date-fns';
import { changePage } from '../action/changePageAction'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers'
import userPlaceHolder from '../restUtilities/userRest/userPlaceHolder';
import Alert from '@material-ui/lab/Alert';
import { configData } from '../configData';
import { languageContext } from '../languageContext';


const styles = theme => ({
    title: {
        textAlign: 'center',
        color: '#1E90FF',
        marginBottom: theme.spacing(1),
        paddingTop: '15px'
    },
    subTitle: {
        textAlign: 'center',
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        paddingTop: '10px',
        paddingBottom: '15px'
    },
    root:{
        textAlign: 'center',
        padding:10,
    },
    sameLine: {
        padding: '10px'
    },
    item: {
        color: '#1E90FF',
        fontWeight: 'bold'
    },
    button: {
        marginBottom: '10px',
        marginTop: '15px'
    },
    container: {
        display:'flex',
        flex:1,
        justifyContent:'center',
        marginTop:20
    },
    formItem: {
        display:'flex',
        flexDirection:'row',
        paddingBottom:30
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
            width: '20ch',
            },
        }
    },
    title: {
        color: '#1E90FF',
    },
    titleBar: {
    background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',

    },
    gridList: {
        width: '80vh',
        height: 500,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 1000,
    },
});
class MakeReview extends React.Component {
    constructor(){
        super();
        this.state = {popup:false, popupError:false, errorPhrase:"",itinerary : undefined,userData:{date:null,pois:[],travel_members_number:null,travel_group_type:null,description:"", isVisited:true,rating:-1}};
        this.members = [];
        for(var i = 1; i <= 10; i++) {
            this.members.push(i);
        }
        this.input = {
            travel_members_number:-1,
            rating:-1,
            description:"",
            date:null,
            travel_group_type:null
        }
    }

    componentDidMount = async () => {
        this.props.changePage("MakeReview");
        const response  = await itinerariesPlaceHolder('/' + this.props.userId + '/' + this.props.match.params.id);
        console.log(response.data);
        var pois = []
        response.data.pois.forEach( (poi)=>{
            pois.push({
                poi_id:poi.poi_id,
                rating:null,
                visiting_time:null,
                revisit_enabled:false
            })
        } )
        this.setState({itinerary:response.data,userData:{...this.state.userData,pois:pois,itinerary_id:response.data.id}});
    }

    sendReview = async () => {
        var userData = this.state.userData
        if(userData.description==="" || userData.rating===-1 || userData.date===null || userData.travel_group_type===null || userData.travel_members_number===null){
            this.setState({popupError:true,errorPhrase:languageContext.get(this.props.language).rev_error})
        }
        else{
            var check = true;
            this.state.userData.pois.forEach((poi)=>{
                if((poi.rating===null&&poi.visiting_time!==null)||(poi.rating!==null&&poi.visiting_time===null)){
                    check=false;
                }
            })
            if(!check){
                this.setState({popupError:true,errorPhrase:languageContext.get(this.props.language).poi_error})
            }
            else{
                var userData=this.state.userData;
                userData.pois=userData.pois.filter((poi)=>{
                    return poi.visiting_time!==null;
                })
                await userPlaceHolder.put(
                    '/feedback/'+this.props.userId,
                    userData
                )
                this.setState({popup:true});
            }     
        }
        
        
    }

    onMemberChange = (event,value) => {
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.travel_members_number = event.target.value;                     
            return { userData };                                 
        })
        this.input.travel_members_number = event.target.value;
    }

    onTravelChange = (event,value) => {
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.travel_group_type = event.target.value;                     
            return { userData };                                 
        })
        this.input.travel_group_type = event.target.value;
    }

    handleDescriptionInput=(value) => {
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.description = value;                     
            return { userData };                                 
        })
        this.input.travel_group_type = value
    }

    handleDateChange = (date) =>{
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.date = date;                     
            return { userData };                                 
        })
        console.log(date)
        this.input.date = date.getDate()
    }

    handleRating = (event,newValue) =>{
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            userData.rating = newValue;                     
            return { userData };                                 
        })
        this.input.rating = newValue
    }

    handlePoiRating = (event,newValue,id)=>{
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 

            userData.pois[userData.pois.findIndex(el => el.poi_id === id)] ={...userData.pois[userData.pois.findIndex(el => el.poi_id === id)],rating:newValue};                   
            return { userData };                                 
        })
    }

    handlePoiTimeVisit = (event,newValue,id)=>{
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 

            userData.pois[userData.pois.findIndex(el => el.poi_id === id)] ={...userData.pois[userData.pois.findIndex(el => el.poi_id === id)],visiting_time:event.target.value};                   
            return { userData };                                 
        })
    }

    handlePoiRevisit = (event,id)=>{
        this.setState(prevState => {
            let userData = Object.assign({}, prevState.userData); 
            var newValue = !userData.pois[userData.pois.findIndex(el => el.poi_id === id)].revisit_enabled
            userData.pois[userData.pois.findIndex(el => el.poi_id === id)] ={...userData.pois[userData.pois.findIndex(el => el.poi_id === id)],revisit_enabled:newValue};                   
            return { userData };                                 
        })
    }

    render() {
        const { classes }=this.props;
        return(
            !this.state.itinerary ?
            <div className={classes.root}>
                <CircularProgress />
            </div> :
            <div>
                <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={this.state.popup}
                onClose={()=>{}}
                aria-labelledby="max-width-dialog-title"
                style={{textAlign:"center"}}  
                >
                    <Alert
                        variant="filled" 
                        severity="success"
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                this.setState({popup:false});
                                this.props.changePage(configData.suggestedItinerary);
                                this.props.history.push('/suggested');
                            }}
                            >
                                <ArrowForwardIosIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {languageContext.get(this.props.language).success_review}
                    </Alert>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={this.state.popupError}
                    onClose={()=>{}}
                    aria-labelledby="max-width-dialog-title"
                    style={{textAlign:"center"}}  
                >
                    <Alert
                        variant="filled" 
                        severity="error"
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                this.setState({popupError:false});
                            }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {this.state.errorPhrase}
                    </Alert>
                </Dialog>
                <IconButton aria-label="back" onClick={this.props.history.goBack}>
                    <ArrowBackIosIcon />
                </IconButton>
                <div className={classes.root}>
                    <Typography variant="h4" className={classes.title}>
                        {languageContext.get(this.props.language).make_review+this.state.itinerary.name}
                    </Typography>
                    
                </div>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} elevation={6}square>
                            <Grid container justify="center">
                                <div className={classes.formItem}>
                                    <Typography variant="h6">
                                        {languageContext.get(this.props.language).rating}
                                    </Typography>
                                    <Rating 
                                        value={this.state.userData.rating}
                                        style={{paddingTop:4,paddingLeft:10}}
                                        onChange={this.handleRating}
                                    />
                                </div>
                                
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} elevation={6}square>
                                
                                    <div className={classes.container} style={{textAlign:'center'}}>
                                        <div>
                                            <Typography variant="h6" style={{marginTop:5}}>
                                                {languageContext.get(this.props.language).date}
                                            </Typography>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    onChange={this.handleDateChange}
                                                    value={this.state.userData.date}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        
                                    </div>
                                        
                                
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Grid item container justify="center" spacing={2}>
                                    <div className={classes.container}>
                                        <div>
                                            <Typography variant="h6" style={{paddingTop:30}}>
                                                {languageContext.get(this.props.language).travel_members_number}
                                            </Typography>
                                            <Select
                                                labelId="members"
                                                id="members"
                                                onChange = {this.onMemberChange}
                                                value={this.state.userData.travel_members_number ? this.state.userData.travel_members_number : null}
                                                style={{marginLeft:100}}
                                            >
                                                {this.members.map((m) =>
                                                    <MenuItem value={m}>{m}</MenuItem>
                                                )}
                                            </Select>
                                        </div>
                                        
                                    </div>
                                </Grid>
                            </Grid>
                                    
                            <Grid item xs={12} sm={12} md={4}>
                                <Grid item container justify="center" spacing={2}>
                                <div className={classes.container}> 
                                    <div>                
                                        <Typography variant="h6" style={{paddingTop:30}}>
                                            {languageContext.get(this.props.language).travel_group_type}
                                        </Typography>
                                        <Select

                                            onChange = {this.onTravelChange}
                                            value={this.state.userData.travel_group_type ? this.state.userData.travel_group_type : null}
                                            style={{width:200}}
                                        >
                                            <MenuItem value={'FAMILY_TRIP'}>{'Viaggio di famiglia'}</MenuItem>
                                            <MenuItem value={'FRIENDS_TRIP'}>{'Viaggio con amici'}</MenuItem>
                                            <MenuItem value={'INDIVIDUAL_TRIP'}>{'Viaggio da solo'}</MenuItem>
                                            <MenuItem value={'COUPLE_TRIP'}>{'Viaggio in coppia'}</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                </Grid>
                            </Grid>      
                        </Grid>
                        <div className={classes.container}>
                            <div style={{textAlign:'center'}}>
                                <Typography variant="h6" style={{paddingTop:20}}>
                                    {languageContext.get(this.props.language).description}
                                </Typography>
                                <TextField id="standard-basic" variant="outlined" onChange={(event)=>this.handleDescriptionInput(event.target.value)} />
                            </div>
                        </div> 
                        
                            
                        <Grid item xs={12} sm={12} md={12} elevation={6} square>
                            <hr
                                style={{
                                    color: '#1E90FF',
                                    backgroundColor: '#1E90FF',
                                    height: 3,
                                    width:'80%'
                                }}
                            />    
                            <Grid container justify="center">
                                <div>
                                    <Typography variant="h6" style={{textAlign:'center'}}>{languageContext.get(this.props.language).pois}</Typography>
                                    <GridList cellHeight={180} className={classes.gridList}>
                                        {this.state.itinerary.pois.map((poi,i)=>{
                                            return(
                                                <GridListTile key={poi.poi_id}>
                                                    <div style={{backgroundColor:'rgba(197, 239, 247, 0.1)',marginTop:50}}>
                                                        <div className={classes.container}>
                                                            <div>
                                                                <Rating 
                                                                    onChange={(event,newValue)=>this.handlePoiRating(event,newValue,poi.poi_id)}
                                                                    style={{paddingTop:4,paddingLeft:10}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop:5}}>
                                                            <div className={classes.formItem}>
                                                                <FormControl className={classes.formControl}>
                                                                    <InputLabel id="visit" style={{fontSize:12}}>{languageContext.get(this.props.language).visiting_time}</InputLabel>
                                                                    <Select
                                                                        labelId="visitTime"
                                                                        id="visitTime"
                                                                        onChange={(event, value)=>this.handlePoiTimeVisit(event,value,poi.poi_id)}
                                                                        style={{marginBottom:30}}
                                                                    >
                                                                        {this.members.map((m) =>
                                                                            <MenuItem value={m}>{m}</MenuItem>
                                                                        )}
                                                                    </Select>                                                               
                                                                </FormControl>
                                                                <div className={classes.formItem}>
                                                                    <Typography variant="body2" style={{marginTop:40, marginLeft:5}}> {languageContext.get(this.props.language).revisit} </Typography>
                                                                    <Checkbox
                                                                        style={{marginTop:26}}
                                                                        onChange={(event)=>this.handlePoiRevisit(event,poi.poi_id)}
                                                                        name="checkedB"
                                                                        color="primary"
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    <GridListTileBar
                                                        title={(i+1)+" - "+poi.short_description}
                                                        titlePosition="top"
                                                    />
                                                    
                                                </GridListTile>
                                            )
                                        })}
                                    </GridList>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.container}>
                        <Button className={classes.button} onClick={this.sendReview} color="primary" variant="contained" style={{width:'50%'}}>
                            {languageContext.get(this.props.language).send_review}
                        </Button>
                    </div>
                </div>
            </div>
        );
        }
    }


const mapStateToProps = (state) => {
    return ({
        userId:state.user.userId,
        language: state.user.language
    });
}

export default connect(mapStateToProps,{
    changePage
})(withStyles(styles)(MakeReview));